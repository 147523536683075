import React from 'react';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';
import Tab from '.';

export default {
  title: 'BRMS/button/Tab',
  component: Tab,
};

const Template = (args) => {
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Tab {...args} />
      <Tab {...args} active />
    </div>
  );
};

export const Default = Template.bind({});
Default.args = {
  text: 'Tab',
  icon: {
    icon: 'empty',
  },
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      const bgColor =
        color == 'positive-300'
          ? `rgba(var(--color-${color}), 0.15)`
          : `rgba(var(--color-${color}), 1)`;
      return (
        <div key={index}>
          <div style={{ backgroundColor: bgColor, display: 'flex' }}>
            <Tab
              {...Default.args}
              active
              text={color.charAt(0).toUpperCase() + color.slice(1)}
              color={color}
            />
            <Tab
              {...Default.args}
              text={color.charAt(0).toUpperCase() + color.slice(1)}
              color={color}
            />
          </div>
          <Divider height={15} color={'light'} />
        </div>
      );
    })}
  </>
);
