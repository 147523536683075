// This is used for making nested forms more dynamic. It swaps out a html node
// with the attribute `data-replaceable-fields` with the same node found in
// html document rendered in a js request. For an example look into
// Admin::RulesController new/show/edit/update and corresponding views.
//
//  --
// Sample code:
// ---

// <%= f.collection_select :rule_kind_type,
//     Rule::KIND_TYPE_OPTIONS,
//     :last,
//     :first,
//     {},
//     { data: { remote: true,
//         ujs: true,
//         url: f.options[:url],
//         method: f.options[:html][:method] } } %>

// <%= react_component('ui.Divider',
//   { height: 15,
//     color: 'transparent' }) %>

// <div data-replaceable-fields>
//   <%= f.collection_select :rule_kind_subtype,
//       @rule.kind_subtype_options_for_select,
//       :last,
//       :first,
//       {},
//       { data: { remote: true, ujs: true,
//           url: f.options[:url],
//           method: f.options[:html][:method] } } %>

//   <%= react_component('ui.Divider',
//     { height: 15,
//       color: 'transparent',
//     }) %>

//   <%= f.fields_for :rule_kind, @rule.rule_kind do |fields| %>
//     <%= render partial: @rule.rule_kind.form_partial_path,
//         locals: { f: fields, question: @rule.rule_kind } %>
//   <% end %>
// <div>


export default function uShiftForm() {

  window.replaceContent = function (content, target) {
    const position_x = document.documentElement.scrollLeft;
    const position_y = document.documentElement.scrollTop;

    let elem = document.querySelector(target);

    if (!elem) return;

    var wrapper = document.createElement('div');
    wrapper.innerHTML = content;

    let divToReplace = wrapper.querySelector(target);
    if (divToReplace == null) {
      divToReplace = wrapper;
    }

    elem.innerHTML = divToReplace.innerHTML;
    ReactRailsUJS.mountComponents(elem);
    window.scrollTo(position_x, position_y);
  };

  document.addEventListener('ajax:before', (event) => {
    if (!event.target.hasAttribute('data-ujs')) {
      return;
    }
    const formData = new FormData(event.target.form);
    let data = [...formData.entries()];
    data.push(['onChange', true]);

    // todo: refactor in the future
    if (event.target.name != undefined) {
      let innerReplace = event.target.closest('[data-html-card-parent="assignment"]');
      if (innerReplace != undefined) {
        innerReplace.setAttribute('id', 'parent-' + Date.now());
        data.push(['replaceCardId', innerReplace.id]);
        data.push(['replaceCardSequence', innerReplace.querySelector('[data-sequence]').value]);
      }
    }

    const asString = data
      .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
      .join('&');
    event.target.setAttribute('data-params', asString);
  });
};