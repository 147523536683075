import React from 'react';
import { Divider } from '@components/ui';
import Element from '.';

export default {
  title: 'BRMS/form/Element',
  component: Element,
};

const Template = (args) => <Element {...args} />;

export const Default = Template.bind({});

Default.args = {};

export const Variants = () => (
  <>
    <Divider height={20} color={'transparent'} />
    <Element label={'This is a label'} />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is a label with a tooltip'}
      tooltip={{ content: { text: 'I am a Tooltip' } }}
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'This is an inline label'} inline />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'ID'}
      placeholder={'This is an inline label with background'}
      inline
      background
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'Password'} type={'password'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'E-mail'} type={'email'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Phone'} type={'tel'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Disabled'} disabled />
    <Divider height={20} color={'transparent'} />
    <Element label={'Readonly'} readonly />
    <Divider height={20} color={'transparent'} />
    <Element remove={{ action: '#' }} label={'This is an input with remove'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Textarea'} type={'textarea'} />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Textarea'}
      type={'textarea'}
      errors={[{ text: 'can`t be blank' }]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Textarea'}
      type={'textarea'}
      errors={[{ text: 'can`t be blank' }, { text: 'second error' }]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Select'}
      type={'select'}
      options={[
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Select'}
      type={'AjaxSelect'}
      options={[
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      remove={{ action: '#' }}
      type={'select'}
      label={'Select with remove'}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Checkbox'}
      type={'checkbox'}
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'claim[gender]',
          value: 'male',
        },
        {
          checked: false,
          label: 'Female',
          name: 'claim[gender]',
          value: 'female',
        },
        {
          checked: false,
          label: 'Other',
          name: 'claim[gender]',
          value: 'other',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Radio'}
      type={'radio'}
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'claim[gender]',
          value: 'male',
        },
        {
          checked: false,
          label: 'Female',
          name: 'claim[gender]',
          value: 'female',
        },
        {
          checked: false,
          label: 'Other',
          name: 'claim[gender]',
          value: 'other',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'radio'}
      button
      label={'Radio select'}
      options={[
        {
          checked: true,
          label: 'Value',
          name: 'same',
          value: 'value',
        },
        {
          checked: false,
          label: 'Range',
          name: 'same',
          value: 'range',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Activation condition'}
      type={'radio'}
      column
      options={[
        {
          checked: false,
          name: 'name',
          value: 'value',
          fields: [
            {
              label: 'if what_illness == broken_bones',
              status: 'Current',
            },
          ],
        },
        {
          checked: false,
          fields: [
            {
              label: 'if what_illness == broken_bones',
              status: 'Update',
            },
            {
              label: '&& what_illness == Bruised_bones',
            },
          ],
          name: 'name',
          value: 'value',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Assignments'}
      type={'radio'}
      column
      options={[
        {
          checked: false,
          name: 'name',
          value: 'value',
          assignment: true,
          fields: [
            {
              label: 'if answer is ill_01 then penalty +1',
              status: 'Current',
            },
            {
              label: 'If answer is ill_02 then penalty +2',
            },
          ],
        },
        {
          checked: false,
          assignment: true,
          fields: [
            {
              label: 'If answer is ill_01 then penalty +1',
              status: 'Update',
            },
          ],
          name: 'name',
          value: 'value',
        },
      ]}
    />
    {/* <Divider height={20} color={'transparent'} />
    <Element label={'Datepicker'} type={'datepicker'} /> */}
  </>
);

export const Prefix_And_Suffix = () => (
  <>
    <Element
      label={'€'}
      placeholder={'This is an prefix label with background'}
      inline
      background
      flat
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'%'}
      placeholder={'This is a suffix label with background'}
      inlineReverse
      background
      flat
    />
  </>
);
export const Placeholder = (args) => (
  <>
    <Element label={'This is a label'} placeholder='label' />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is an inline label'}
      inline
      placeholder='inline label'
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'E-mail'} type={'email'} placeholder='email' />
    <Divider height={20} color={'transparent'} />
    <Element label={'Phone'} type={'tel'} placeholder='phone number' />
    <Divider height={20} color={'transparent'} />
    <Element label={'Disabled'} disabled placeholder='disabled' />
    <Divider height={20} color={'transparent'} />
    <Element label={'Readonly'} readonly />
    <Divider height={20} color={'transparent'} />
    <Element label={'Textarea'} type={'textarea'} placeholder='textarea' />
    {/* <Divider height={20} color={'transparent'} />
    <Element label={'Datepicker'} type={'datepicker'} /> */}
  </>
);
