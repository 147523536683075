import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Badge } from '@components/badge';
import { Content } from '@components/text';
import { Button } from '@components/button';
import { Divider } from '@components/ui';
import styles from './flat.module.scss';

const cx = classNames.bind(styles);

const FlatRow = ({
  column,
  colspan,
  contentClassName,
  className,
  last,
  final,
  simple,
}) => {
  const columnClasses = cx(
    {
      cell: !simple,
      last,
      final,
      simple,
    },
    className
  );
  const contentClasses = cx(
    {
      cellcontent: true,
      simpleCell: simple,
    },
    contentClassName
  );
  return (
    <td className={columnClasses} colSpan={colspan || 1}>
      <div className={contentClasses}>
        {column.action ? (
          <Button link className={styles.button} {...column} />
        ) : (
          <Content color={simple ? 'balanced-500' : 'dark'} {...column} />
        )}
        {column.badges && (
          <div className={styles.badges}>
            {column.badges.map((badge, index) => (
              <>
                <div key={index} className={styles.badges}>
                  <Badge
                    key={index}
                    type={badge.type}
                    size={'s'}
                    content={badge}
                  />
                  {index > -1 && <Divider width={5} color={'transparent'} />}
                </div>
                {(badge.text.replace(/\s/g, '') === '&&' ||
                  badge.text.replace(/\s/g, '') === '||') && (
                  <div className={styles.break} />
                )}
              </>
            ))}
          </div>
        )}
      </div>
    </td>
  );
};

FlatRow.propTypes = {
  // Contents of the cell
  column: PropTypes.shape({}).isRequired,
  // Width of column
  colspan: PropTypes.number,
  // Classname of content
  contentClassName: PropTypes.string,
  // Overhailing classname
  className: PropTypes.string,
  // Add specific last cell styling
  last: PropTypes.bool,
  // Add specific final cell styling
  final: PropTypes.bool,
  // Add specific simple cell styling
  simple: PropTypes.bool,
};

FlatRow.defaultProps = {
  colspan: null,
  contentClassName: '',
  className: '',
  last: false,
  final: false,
  simple: false,
}

export default FlatRow;
