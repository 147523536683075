import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import ReactModal from 'react-modal';
import Wrapper from '@components/card/wrapper';
import Title from '@components/text/title';
import Button from '@components/button/button';
import Divider from '@components/ui/divider';
import CodeEditor from '@components/form/codeEditor';
import AjaxSelect from '@components/form/ajaxSelect';
import styles from '../../../modal/modal/modal.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prev, next) => prev === next;

const ActivationModal = ({
  title,
  isOpen,
  onRequestClose,
  children,
  completions,
  size,
  color,
  className,
  content,
  select,
  storybook,
  onSelect,
}) => {
  const { header, badges } = content && content.props ? content.props : {};
  const [modalIsOpen, setIsOpen] = useState(isOpen);
  const [aceContent, setAceContent] = useState('');
  const [prefilledBadges, setPrefilledBadges] = useState('');
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState(null);
  const [valid, setValid] = useState(true);

  const functionValues = [
    'today',
    'age_in_year',
    'age_in_months',
    'included',
    'function',
  ];

  const completionValues = completions.map((x) => x.value);

  const colorPicker = (badge) => {
    const convertedBadge = badge.toLowerCase();
    if (convertedBadge === 'true' || convertedBadge == 'null')
      return 'energized';
    if (convertedBadge === 'false') return 'assertive';
    if (completionValues.includes(convertedBadge)) return 'positive';
    if (functionValues.includes(convertedBadge)) return 'royal';
    return 'balanced-500';
  };

  useEffect(() => {
    if (badges) {
      const data = badges[0].values.map((x) => x.content.text).join(' ');
      if (!isOpen) {
        setPrefilledBadges('');
      } else {
        setPrefilledBadges(data);
      }
    }
    setIsOpen(isOpen);
  }, [isOpen]);

  const toggleModal = (e) => {
    // setIsOpen(!modalIsOpen);
    if (onRequestClose) {
      const values = aceContent.split(' ');
      const mappedBadges =
        card ||
        values.map((value) => ({
          color: colorPicker(value),
          content: {
            text: value,
          },
        }));
      setPrefilledBadges('');
      setCard(null);
      onRequestClose(e, mappedBadges, card);
    }
  };

  const updateCard = (e) => {
    const cardData = {
      id: e.id,
      component: 'QuestionCard',
      props: {
        label: {
          text: e.detail,
        },
        title: {
          text: e.value,
        },
        content: [],
      },
      position: {
        x: 500,
        y: 40,
      },
      ports: {
        port1: {
          id: 'port1',
          type: 'top',
          properties: {
            type: 'in',
            value: 'no',
          },
        },
        port2: {
          id: 'port2',
          type: 'bottom',
          properties: {
            type: 'out',
            value: 'no',
          },
        },
      },
    };

    setCard(cardData);
  };
  const classes = cx(
    {
      modal: true,
    },
    color,
    size,
    className
  );
  const overlayClasses = cx({
    overlay: true,
  });

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={() => toggleModal('close')}
      className={classes}
      overlayClassName={overlayClasses}
      ariaHideApp={false}
      portalClassName={'portal-node'}
    >
      <Divider height={20} color={'transparent'} />
      <Wrapper size={'xs'}>
        <div className={styles.heading}>
          {title && <Title {...title} />}
          <Button
            color={'dark'}
            className={styles.remove}
            link
            text={false}
            icon={{ icon: 'cross', width: 28, height: 28 }}
            action={() => toggleModal('close')}
          />
        </div>
        <Divider height={5} color={'transparent'} />
        <Divider height={1} color={'stable-500'} />
        <Divider height={15} color={'transparent'} />
        {!select && (
          <CodeEditor
            setValidity={(e) => setValid(e)}
            setContent={(e) => setAceContent(e)}
            initialValue={prefilledBadges}
            completions={completions}
            storybook={storybook}
          />
        )}
        {select && <AjaxSelect onSelect={(e) => updateCard(e)} open />}
        {children && <div className={styles.content}>{children}</div>}
        <Divider height={15} color={'transparent'} />
        <Divider height={1} color={'stable-500'} />
        <Divider height={15} color={'transparent'} />
        <Button
          color={'positive'}
          text={'Save'}
          disabled={!valid}
          action={() => toggleModal('save')}
          className={styles.alignEnd}
          loading={loading}
        />
      </Wrapper>
      <Divider height={20} color={'transparent'} />
    </ReactModal>
  );
};
ActivationModal.propTypes = {
  /** The color variant of the modal */
  color: PropTypes.oneOf(Colors),
  /** To size of the modal (spacing between modal & content) */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The title inside the modal */
  title: PropTypes.shape(Title.propTypes),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** Adds a custom class to the button */
  className: PropTypes.string,
  /** Adds completions to the editor */
  completions: PropTypes.arrayOf(PropTypes.shape({})),
  /** Prefilled content of the ace editor */
  content: PropTypes.shape({
    props: PropTypes.shape({
      header: PropTypes.shape({
        title: PropTypes.shape({}),
      }),
      badges: PropTypes.arrayOf(
        PropTypes.shape({
          values: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
    }),
  }),
  /** Fake storybook input */
  storybook: PropTypes.bool,
  /** Custom close request */
  onRequestClose: PropTypes.func,
  /** Shows the ajax select in the modal */
  select: PropTypes.bool,
};

ActivationModal.defaultProps = {
  color: 'light',
  size: 'xs',
  title: null,
  completions: [],
  isOpen: false,
  className: '',
  content: {
    props: {},
  },
  storybook: false,
  onRequestClose: null,
  select: false,
};

// Needed for Storybook
ActivationModal.displayName = 'ActivationModal';

export default React.memo(ActivationModal, checkEqual);
