import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import Divider from '@components/ui/divider';
import Table from '@components/table/table';
import Reader from '@components/form/reader';
import Button from '@components/button/button';
import { Title, Detail } from '@components/text';
import Wrapper from '../wrapper';
import styles from './ruleCard.module.scss';

const cx = classNames.bind(styles);

const RuleCard = ({
  size,
  color,
  header,
  className,
  questionDetails,
  answerDetails,
  title,
  truncateNumber,
}) => {
  const [truncateAnswers, setTruncateAnswers] = useState(true);
  const lineClass = cx({
    [`line-${size}`]: true,
  });

  const answerClasses = cx({
    answer: true,
    tableFold: !truncateAnswers,
  });

  return (
    <Wrapper size={size} color={color} header={header} className={className}>
      {questionDetails &&
        questionDetails.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider color={'transparent'} height={15} />}
            <Detail
              row
              label={{ color: 'dark' }}
              minWidth={'xxl'}
              classname={styles.detail}
              {...item}
            />
          </div>
        ))}
      {title && <Title className={styles.title} size={'h4'} {...title} />}
      {title && answerDetails && (
        <Divider className={lineClass} color={'stable-500'} height={1} />
      )}
      {answerDetails && (
        <div className={styles.answers}>
          {answerDetails.map((item, index) => (
            <div key={index}>
              {index !== 0 && <Divider color={'transparent'} height={15} />}
              <div className={answerClasses}>
                <Detail
                  row
                  minWidth={'xxl'}
                  classname={styles.detail}
                  {...item}
                />
                {item.table && (
                  <Wrapper
                    color={'stable-300'}
                    className={styles.answerwrapper}
                  >
                    {!truncateAnswers && (
                      <Divider color={'transparent'} height={30} width={10} />
                    )}
                    <Table
                      simple
                      sizes={[33, 33, 33]}
                      className={styles.table}
                      head={item.table.head}
                      body={
                        truncateAnswers
                          ? item.table.body.slice(0, truncateNumber)
                          : item.table.body
                      }
                      {...item}
                    />
                    {truncateAnswers &&
                      item.table.body.length > truncateNumber && (
                        <>
                          <Divider color={'transparent'} height={15} />
                          <Button
                            link
                            text={`Show ${item.table.body.length - truncateNumber
                              } more`}
                            action={() => setTruncateAnswers(false)}
                          />
                        </>
                      )}
                  </Wrapper>
                )}
                {item.reader && (
                  // TODO: needs styling like the table in rule card
                  <Reader
                    persisted_content={item.reader.persisted_content}
                    tableOnly={item.reader.tableOnly}
                    {...item}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

RuleCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf([...Colors]),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The header of the card */
  header: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  /** The title of the content */
  title: PropTypes.shape(Title.propTypes),
  /** The question details of the card */
  questionDetails: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  /** The answer details of the card */
  answerDetails: PropTypes.arrayOf(PropTypes.shape({})),
  /** Adds a custom class to the card */
  className: PropTypes.string,
  /** Number of max default rows */
  truncateNumber: PropTypes.number,
};

RuleCard.defaultProps = {
  color: 'light',
  size: 'xl',
  header: false,
  className: '',
  truncateNumber: 3,
  answerDetails: [],
  questionDetails: [],
  title: {},
};

// Needed for Storybook
RuleCard.displayName = 'RuleCard';

export default RuleCard;
