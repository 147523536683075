import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import ReactModal from 'react-modal';
import Wrapper from '@components/card/wrapper';
import Title from '@components/text/title';
import Button from '@components/button/button';
import Divider from '@components/ui/divider';
import styles from './modal.module.scss';

const cx = classNames.bind(styles);

const Modal = ({
  isOpen,
  hasFooter,
  color,
  size,
  className,
  id,
  trigger,
  title,
}) => {
  const [modalIsOpen, setIsOpen] = useState(isOpen);
  const [isTransformed, setisTransformed] = useState(false);
  const [modalHasFooter] = useState(hasFooter);
  const contentWrapper = useRef();
  const footerWrapper = useRef();
  const toggleModal = () => {
    if (modalIsOpen) {
      if (document) {
        document.querySelector('.portal-node').remove();
      }
    }
    setIsOpen(!modalIsOpen);
  };
  const classes = cx(
    {
      modal: true,
    },
    color,
    size,
    className
  );
  const overlayClasses = cx({
    overlay: true,
  });

  useEffect(() => {
    if (id && !isTransformed) {
      const elem = document ? document.getElementById(id) : false;
      if (elem) {
        setisTransformed(true);
        toggleModal();
      }
    }
  }, []);

  const _moveContent = () => {
    if (id) {
      const template = document ? document.getElementById(id) : false;
      if (template && contentWrapper.current) {
        const footer = template.querySelector('[data-footer]');
        if (template.tagName.toLocaleLowerCase() === 'template') {
          const clone = template.content.cloneNode(true);
          contentWrapper.current.appendChild(clone);
        } else {
          contentWrapper.current.appendChild(template);
          template.classList.remove('u-hide');
        }
        if (typeof ReactRailsUJS !== 'undefined') {
          ReactRailsUJS.mountComponents(template);
        }
        if (footer && footerWrapper.current) {
          footerWrapper.current.appendChild(footer);
          if (typeof ReactRailsUJS !== 'undefined') {
            ReactRailsUJS.mountComponents(footer);
          }
        }
      }
    }
  };

  return (
    <>
      {trigger && (
        <Button
          className={styles.trigger}
          link
          text={'Open Modal'}
          action={toggleModal}
          {...trigger}
        />
      )}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        className={classes}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
        onAfterOpen={_moveContent}
        portalClassName={'portal-node'}
      >
        <Divider height={20} color={'transparent'} />
        <Wrapper size={size}>
          <div className={styles.heading}>
            {title && <Title {...title} />}
            <Button
              color={'dark'}
              className={styles.remove}
              link
              text={false}
              icon={{ icon: 'cross', width: 28, height: 28 }}
              action={toggleModal}
            />
          </div>
          <Divider height={30} color={'transparent'} />
          <div className={styles.content} ref={contentWrapper} />
          {modalHasFooter && (
            <div className={styles.footer} ref={footerWrapper} />
          )}
        </Wrapper>
        <Divider height={20} color={'transparent'} />
      </ReactModal>
    </>
  );
};
Modal.propTypes = {
  /** The color variant of the modal */
  color: PropTypes.oneOf(Colors),
  /** To size of the modal (spacing between modal & content) */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The data for the trigger button */
  trigger: PropTypes.shape(Button.propTypes),
  /** The title inside the modal */
  title: PropTypes.shape(Title.propTypes),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** Does the modal have a footer */
  hasFooter: PropTypes.bool,
  /** Adds a custom class to the button */
  className: PropTypes.string,
  /** The id of the hidden div to be placed inside this card */
  id: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  color: 'light',
  size: 'xl',
  trigger: null,
  title: null,
  isOpen: false,
  className: '',
  hasFooter: false,
};

// Needed for Storybook
Modal.displayName = 'Modal';

export default Modal;
