import Rails from '@rails/ujs';

/** ***
 * API Helper
 */
const callApi = (url, data = {}, method = 'GET') =>
  new Promise((resolve, reject) => {
    fetch(url, {
      method,
      body: JSON.stringify({ ...data, ...{ 'diagram': true } }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest', // ugly-hack to let rails recognize this as Ajax
        Accept:
          'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
      },
      credentials: 'same-origin',
      timeout: 35000,
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((e) => reject(e));
  });

export default callApi;
