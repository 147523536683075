import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { REACT_FLOW_CHART } from '@mrblenny/react-flow-chart';
import styles from '../diagram.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prev, next) => prev === next;

const CustomCanvas = React.forwardRef(({ children }, ref) => {
  const classes = cx({
    diagram: true,
  });

  return (
    <div ref={ref} className={classes}>
      <div>{children}</div>
    </div>
  );
});

CustomCanvas.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(CustomCanvas, checkEqual);
