import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from '@components/ui/icon';
import Divider from '@components/ui/divider';
import Title from '@components/text/title';
import styles from './file.module.scss';

const cx = classNames.bind(styles);

const File = (props) => {
  const [enter, setEnter] = useState(false);
  const [file, setFile] = useState(false);
  const classes = cx(
    {
      file: true,
      multiple: props.multiple,
      enter,
    },
    props.className
  );
  const files = props.multiple ? 'file(s)' : 'file';
  const handleDrop = (e) => {
    setEnter(false);
    const dt = e.dataTransfer;
    const { files } = dt;
    [...files].forEach((file, index) => {
      setFile(file.name);
    });
  };
  const handleChange = (e) => {
    setEnter(false);
    const fullPath = e.currentTarget.value;
    const startIndex =
      fullPath.indexOf('\\') >= 0
        ? fullPath.lastIndexOf('\\')
        : fullPath.lastIndexOf('/');
    let filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1);
    }
    setFile(filename);
  };
  return (
    <div className={classes}>
      <div className={styles.inner}>
        <Icon
          icon={'add-file'}
          width={50}
          height={50}
          color={'positive'}
          stroke
        />
        <Divider height={20} color={'transparent'} />
        {file ? (
          <Title
            text={file}
            inline
            size={'body'}
            emphasis
            color={'balanced-500'}
          />
        ) : (
          <div>
            <Title
              text={`Drag and drop document ${files} here to upload or `}
              inline
              size={'body'}
              emphasis
              color={'balanced-500'}
            />
            <label htmlFor={props.id}>
              <Title
                text={`choose a file`}
                inline
                size={'body'}
                emphasis
                color={'positive'}
              />
            </label>
          </div>
        )}
      </div>
      <input
        id={props.id}
        className={styles.input}
        type={props.type}
        name={props.name}
        disabled={props.disabled}
        readOnly={props.readonly}
        placeholder={props.placeholder}
        onDragEnter={() => setEnter(true)}
        onDragLeave={() => setEnter(false)}
        onDrop={handleDrop}
        onChange={(e) => {
          props.onChange && props.onChange(e);
          handleChange(e);
        }}
      />
    </div>
  );
};

File.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['file']).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
  /** Single vs multi file upload */
  multiple: PropTypes.bool,
};

File.defaultProps = {
  type: 'file',
  name: 'file',
};

// Needed for Storybook
File.displayName = 'File';

export default File;
