// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from '@rails/ujs';
import WebFont from 'webfontloader';
import uShiftForm from 'lib/u_shift_form';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require("packs/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

const fontStack = `${getComputedStyle(document.documentElement)
  .getPropertyValue('--font-stack')
  .trim()}:400,500,700`;
const fontStackAlt = `${getComputedStyle(document.documentElement)
  .getPropertyValue('--font-stack-alt')
  .trim()}:400,500,700`;

const fontFamilies =
  fontStack != fontStackAlt ? [fontStack, fontStackAlt] : [fontStack];

WebFont.load({
  google: {
    families: fontFamilies,
  },
});

window.launchModal = function (content) {
  if (document.querySelector('.portal-node')) {
    document.querySelector('.portal-node').remove();
  }

  var container = document.createElement('div');
  container.innerHTML = content;
  document.body.appendChild(container);

  ReactRailsUJS.mountComponents(container);
  Rails.refreshCSRFTokens();

  container.remove();
};

function initialLoad() {
  Rails.refreshCSRFTokens();
}
document.addEventListener('turbolinks:load', initialLoad);

function afterCocoon(customEvent) {
  let nodes = customEvent.target.querySelectorAll('.cocoon-inner');
  let previousNode = nodes[nodes.length - 2];
  let newNode = nodes[nodes.length - 1];

  let sequenceNode = newNode.querySelector('[data-sequence]');
  if (sequenceNode) {
    if (previousNode) {
      sequenceNode.value = parseInt(previousNode.querySelector('[data-sequence]').value) + 1;
    } else {
      sequenceNode.value = 1;
    }
  }

  ReactRailsUJS.mountComponents(newNode);
}

window.deeplyNestedCocoon = () => {
  let nodes = document.querySelectorAll('[data-add-variable]');
  nodes.forEach((node, index) => {
    let insertion_node = node.closest('.u-grid').closest('.nested-fields').querySelector('.cocoon-assignment-rows');
    const uniqueId = "unique-" + Date.now() + index;
    insertion_node.setAttribute('id', uniqueId);
    node.setAttribute("data-association-insertion-node", "#" + uniqueId);
  });
}

document.addEventListener('cocoon:after-insert', afterCocoon);
document.addEventListener('turbolinks:load', window.deeplyNestedCocoon);
document.addEventListener('cocoon:after-insert', window.deeplyNestedCocoon);
uShiftForm();