import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import { Title, Content } from '@components/text';
import styles from './option.module.scss';

const cx = classNames.bind(styles);

const Option = (props) => {
  const classes = cx(
    {
      wrapper: true,
      radio: props.type == 'radio',
      checkbox: props.type == 'checkbox',
      disabled: props.disabled,
      readonly: props.readonly,
      fields: props.fields,
      button: props.button,
    },
    props.className
  );

  const classesFields = cx({
    field: true,
    assignment: props.assignment,
  });

  const label = props.text ? props.text : props.label ? props.label : false;
  const value = props.value ? props.value : props.type == 'radio' ? 0 : 1;

  return (
    <label className={classes}>
      <input
        ref={props.reference}
        id={props.id}
        className={styles.input}
        type={props.type}
        name={props.name}
        value={value}
        disabled={props.disabled}
        readOnly={props.readonly}
        defaultChecked={props.checked}
        onChange={props.onChange && props.onChange}
        {...props.attributes}
      />
      <span className={styles.fakeInput} />
      {!props.button && <Divider width={10} color={'transparent'} />}
      {props.fields && !label ? (
        <div className={styles.fieldRow}>
          {props.fields.map((item, index) => (
            <>
              {index !== 0 && props.assignment && (
                <Divider height={5} color={'transparent'} />
              )}
              <div key={index} className={classesFields}>
                <Content
                  className={styles.status}
                  text={item.status}
                  size={'s'}
                />
                <Title
                  className={styles.title}
                  text={item.label}
                  size={'body'}
                />
              </div>
            </>
          ))}
        </div>
      ) : (
        label && (
          <Title className={styles.title} text={label} size={'body'} inline />
        )
      )}
    </label>
  );
};

Option.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['checkbox', 'radio']),
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string,
  /** Multiple items in the option field */
  fields: PropTypes.array,
  /** The status in the option field */
  status: PropTypes.shape(Content.PropTypes),
  /** If the option field is an assignment */
  assignment: PropTypes.bool,
  /** Applies button select styling */
  button: PropTypes.bool,
};

Option.defaultProps = {
  type: 'checkbox',
  name: 'checkBox',
  assignment: false,
  button: false,
};

// Needed for Storybook
Option.displayName = 'Option';

export default Option;
