import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import Table from '@components/table/table';
import styles from './reader.module.scss';
import Element from '../element';
import Content from '../../text/content';

const cx = classNames.bind(styles);

const Reader = ({
  upload,
  selects,
  persisted_content,
  allowed_extensions,
  tableOnly,
  className,
}) => {
  const [headers, setHeaders] = useState([]);
  const [content, setContent] = useState(persisted_content);
  const [errors, setErrors] = useState([]);
  const classes = cx(
    {
      reader: true,
    },
    className
  );

  const readFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev) => {
      import('xlsx/xlsx.mini').then((XLSX) => {
        const data = new Uint8Array(ev.target.result);
        const wb = XLSX.read(data, { type: 'array' });

        const parsedSpreadsheet = XLSX.utils.sheet_to_json(
          wb.Sheets[wb.SheetNames[0]],
          {
            raw: false,
            header: 1,
          }
        );

        setContent(parsedSpreadsheet);

        const contentHeaders = parsedSpreadsheet[0].map((header, index) => ({
          value: index + 1,
          label: header,
        }));

        setHeaders(contentHeaders);
      });
    };
    let allowed = false;
    allowed_extensions.forEach((ext) => {
      if (file.name.endsWith(ext)) {
        allowed = true;
        reader.readAsArrayBuffer(file);
        setErrors([]);
      }
    });
    if (!allowed) {
      setHeaders([]);
      setContent([]);
      setErrors([{ text: 'File not allowed' }]);
    }
  };
  return (
    <div className={classes}>
      {!tableOnly && (
        <Element
          type={'file'}
          onChange={readFile}
          errors={errors}
          {...upload}
        />
      )}
      {content.length > 0 && (
        <>
          <Divider height={20} color={'transparent'} />
          <div className={styles.preview}>
            <Table
              head={content[0].map((cell) => ({ text: cell }))}
              sizes={Array(content[0].length).fill(100 / content[0].length)}
              body={content
                .filter((r, i) => i > 0 && i < 11)
                .map((row) => {
                  const r = {
                    data: row.map((cell) => ({
                      text: cell,
                    })),
                  };
                  return r;
                })}
              foot={
                content.length > 12
                  ? [
                    {
                      colspan: content[0].length,
                      text: `+${content.length - 12} row(s)`,
                    },
                  ]
                  : []
              }
            />
          </div>
        </>
      )}
      {selects.length > 0 && (
        <>
          <Divider height={20} color={'transparent'} />
          <div className={styles.row}>
            {selects.map((select, index) => (
              <div key={index} className={styles.cell}>
                <Element
                  className={styles.cell}
                  type={'select'}
                  {...select}
                  options={
                    headers.length == 0 &&
                      select.options &&
                      select.options.length > 0
                      ? select.options
                      : headers
                  }
                />
                {index < selects.length - 1 && (
                  <Divider width={20} color={'transparent'} />
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

Reader.propTypes = {
  /** The form field attributes for the upload field */
  upload: PropTypes.shape(Element.propTypes),
  /** The form field attributes for the selects field */
  selects: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  /** Inital content, used when data is available in DB */
  persisted_content: PropTypes.shape([]),
  /** Which extensions are allowed to upload */
  allowed_extensions: PropTypes.arrayOf(PropTypes.oneOf(['xlsx'])),
  /** To only show the table with data & not the upload form */
  tableOnly: PropTypes.bool,
};

Reader.defaultProps = {
  upload: null,
  selects: [],
  persisted_content: null,
  allowed_extensions: ['xlsx'],
  tableOnly: false,
};

// Needed for Storybook
Reader.displayName = 'Reader';

export default Reader;
