import React from 'react';
import PropTypes, { node } from 'prop-types';
import {
  actions,
  FlowChart,
  REACT_FLOW_CHART,
} from '@mrblenny/react-flow-chart';
import dagre from 'dagre';
import Button from '../../button/button';
import Wrapper from '../../card/wrapper';
import ActivationModal from './partials/activationModal';
import CustomCanvas from './partials/customCanvas';
import CustomNode from './partials/customNode';
import CustomPort from './partials/customPort';
import CustomNodeInner from './partials/customNodeInner';
import callApi from './api';

import styles from './diagram.module.scss';

export class Diagram extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = data;
    this.wrapper = React.createRef();
    this.resizeObserver = null;
    this.stateActions = Object.entries(actions).reduce(
      (prev, [key, val]) => ({
        ...prev,
        [key]: (...args) => this.setState(val(...args)),
      }),
      {}
    );
    const {
      onDragNodeStop,
      onLinkClick,
      onLinkComplete,
      onCanvasClick,
      onLinkStart,
      onDragNode,
      onLinkMove,
    } = this.stateActions;
    this.stateActions.onDeleteKey = (e) => {
      return false;
    };

    this.stateActions.onDragNode = (e) => {
      onDragNode(e);
      if (this.state.previewId) {
        const { nodes } = this.state;
        nodes[this.state.previewId].position = {
          x: e.data.x + 200,
          y: e.data.y + 70,
        };
      }
    };

    this.stateActions.onDragNodeStop = async (node) => {
      onDragNodeStop(node);

      const id = node.id.replace('node_', '');

      const data = {
        subset_rule: {
          x: node.data.x,
          y: node.data.y,
        },
      };
      if (this.state.actions && this.state.actions.save_position) {
        const { nodes } = this.state;
        nodes[node.id].loading = false;
        callApi(
          this.state.actions.save_position.replace('/0?', `/${id}?`),
          data,
          'PATCH'
        )
          .then((result) => {
            nodes[node.id].loading = false;
          })
          .catch((e) => {
            console.log(e);
            nodes[node.id].loading = 'error';
            nodes[node.id].loading = false;
          });
      }
    };

    this.stateActions.onLinkClick = (e) => {
      onLinkClick(e);
      this.resetLinkClick();
      const node = this.state.nodes[e.linkId];
      if (node) {
        const { nodes } = this.state;
        nodes[e.linkId].hidden = false;
        nodes[e.linkId].position = {
          x: this.state.clicked.x + 25,
          y: this.state.clicked.y - 50,
        };
        this.setState({
          linkId: e.linkId,
        });
      }
    };
    this.stateActions.onLinkStart = (e) => {
      //don't allow line dragging from top to bottom
      if (!e.fromPortId.includes('_top')) {
        onLinkStart(e);
      }
    };
    this.stateActions.onLinkMove = (e) => {
      //don't allow line dragging from top to bottom
      if (!e.fromPortId.includes('_top')) {
        onLinkMove(e);
      }
    };

    this.stateActions.onLinkComplete = (e) => {
      const isAllowed = this.validateLink(e);
      if (isAllowed) {
        this.setState({
          modalVisible: true,
          pendingLink: {
            add: true,
            id: e.linkId,
            data: e,
          },
        });
      } else {
        const { links } = this.state;
        delete links[e.linkId];
        this.setState({
          links,
        });
      }
    };

    this.stateActions.onCanvasClick = (e) => {
      onCanvasClick(e);
      this.resetLinkClick();
      this.resetPreviewClick();
    };
  }

  componentDidMount() {
    const { autolayout } = this.props;
    const { nodes, links } = this.state;
    const mutatedNodes = [];
    let mutatedLinks = links;
    const savedPositions = {};
    this.setState({
      modalVisible: false,
      addRule: false,
      pendingLink: null,
    });
    if (nodes && Object.values(nodes).length > 0) {
      Object.values(nodes).forEach((node, index) => {
        if (
          node.position &&
          node.position.x != null &&
          node.position.y != null
        ) {
          savedPositions[node.id] = node.position;
        }
      });
    }

    if (this.wrapper.current && typeof window !== 'undefined') {
      this.wrapper.current.addEventListener('click', this.storeXY);
      this.resizeObserver = new ResizeObserver(() => {
        const height = window.innerHeight - this.wrapper.current.offsetTop;
        this.wrapper.current.style.height = `${height}px`;
      });
      this.resizeObserver.observe(document.body);
      const height = window.innerHeight - this.wrapper.current.offsetTop;
      this.wrapper.current.style.height = `${height}px`;
    }
    if (autolayout && nodes && Object.keys(nodes).length > 0) {
      const g = new dagre.graphlib.Graph();
      g.setGraph({ rankdir: 'TB' });
      g.setDefaultEdgeLabel(() => ({}));
      Object.keys(nodes).forEach((nodeId) => {
        g.setNode(nodeId, { width: 365, height: 200 });
      });

      if (links && Object.keys(links).length > 0) {
        Object.keys(links).forEach((linkId) => {
          g.setEdge(links[linkId].from.nodeId, links[linkId].to.nodeId);
        });
      }
      dagre.layout(g, {});
      Object.keys(nodes).forEach((nodeId) => {
        mutatedNodes[nodeId] = {
          ...nodes[nodeId],
          position: {
            x:
              savedPositions[nodeId] && savedPositions[nodeId].x != null
                ? savedPositions[nodeId].x
                : 50 + g.node(nodeId).x - 365 / 2, // dagre gives position from the center of node
            y:
              savedPositions[nodeId] && savedPositions[nodeId].y != null
                ? savedPositions[nodeId].y
                : 50 + g.node(nodeId).y - 200 / 2,
          },
          ports: nodes[nodeId].ports ? nodes[nodeId].ports : {},
        };
        if (mutatedNodes[nodeId]?.preview) {
          mutatedNodes[`preview_${nodeId}`] = {
            component: mutatedNodes[nodeId].preview.component,
            id: `preview_${nodeId}`,
            position: {
              x: 0,
              y: 0,
            },
            ports: {},
            props: mutatedNodes[nodeId]?.preview?.props,
            hidden: true,
            readonly: true,
          };
        }
      });
      if (!mutatedLinks) {
        mutatedLinks = {};
      }
      Object.keys(mutatedLinks).forEach((linkId) => {
        if (mutatedLinks[linkId].component) {
          mutatedNodes[linkId] = this.addNodeFromLink(
            mutatedLinks[linkId],
            linkId
          );
        }
      });
      this.setState({
        nodes: mutatedNodes,
        links: mutatedLinks,
        initialized: true,
        scale: 0.99, //hack to make dragging of lines work
      });
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    this.wrapper &&
      this.wrapper.current.removeEventListener('click', this.storeXY);
  }

  addNodeFromLink = (link, linkId) => {
    return {
      component: link.component,
      id: linkId,
      position: {
        x: 0,
        y: 0,
      },
      ports: {},
      props: link.props
        ? {
            ...link.props,
            header: {
              ...link.props.header,
              buttons: [
                {
                  icon: {
                    icon: 'trash',
                  },
                  color: 'assertive',
                  action: () => {
                    this.deleteLink(linkId);
                  },
                },
                {
                  icon: {
                    icon: 'edit',
                  },
                  color: 'positive',
                  action: () => {
                    this.editLink(linkId);
                  },
                },
              ],
            },
          }
        : {},
      hidden: true,
      readonly: true,
    };
  };

  addNode = (data) => {
    const { actions, subset, nodes } = this.state;
    callApi(
      actions.add_rule.replace('/0', `/${subset}`),
      {
        subset_rule: {
          subset_id: subset,
          rule_id: data.id,
        },
      },
      'POST'
    )
      .then((resp) => resp.json())
      .then((e) => {
        this.setState({
          addRule: false,
          nodes: {
            ...nodes,
            [`node_${e.id}`]: {
              ...data,
              id: `node_${e.id}`,
              props: {
                ...data.props,
                label: { text: e.label },
                content: [{ text: e.name }],
              },
              url: actions.update_rule.replace('/0', `/${e.id}/edit`),
            },
          },
        });
      })
      .catch((e) => {
        console.log(e);
        alert('Something went wrong');
      });
  };

  deleteNode = (node) => {
    const { actions, links, nodes } = this.state;
    if (
      !node.readonly &&
      window.confirm(
        "Are you sure you want to delete this card & it's connections?"
      )
    ) {
      const id = node.id.replace('node_', '');
      callApi(actions.delete_rule.replace('/0', `/${id}`), {}, 'DELETE')
        .then((e) => {
          // Delete the connected links
          Object.keys(links).forEach(function (linkId) {
            const link = links[linkId];
            if (link.from.nodeId === node.id || link.to.nodeId === node.id) {
              delete links[link.id];
            }
          });
          // Delete the node
          delete nodes[node.id];

          this.setState({
            links: links,
            nodes: nodes,
          });
        })
        .catch((e) => {
          console.log(e);
          alert('Something went wrong');
        });
    }
  };

  previewNode = (node) => {
    const id = `preview_${node.id}`;
    const { nodes } = this.state;
    if (this.state.previewId) {
      nodes[this.state.previewId].hidden = true;
    }
    if (nodes[id]) {
      nodes[id].hidden = false;
      nodes[id].position = {
        x: nodes[node.id].position.x + 200,
        y: nodes[node.id].position.y + 70,
      };
      this.setState({
        previewId: id,
      });
    }
  };

  validateLink = ({ fromNodeId, fromPortId, toNodeId, toPortId }) => {
    const { nodes } = this.state;
    if (
      nodes[fromNodeId].ports[fromPortId].properties.type ===
      nodes[toNodeId].ports[toPortId].properties.type
    ) {
      return false;
    }
    return true;
  };

  addLink = (data, status) => {
    const { pendingLink, links, nodes, subset, actions } = this.state;
    if (status !== 'save') {
      delete links[pendingLink.id];
      this.setState({
        links,
        pendingLink: null,
      });
    } else {
      const id = pendingLink.data.toNodeId.replace('node_', '');
      const condition = data ? data.map((item) => item.content.text) : [];
      const postData = {
        subset_rule: {
          subset_id: subset,
          evaluation_attributes: {
            condition: condition.join(' '),
          },
        },
      };
      callApi(
        `${actions.save_position.replace('/0', `/${id}`)}`,
        postData,
        'PATCH'
      )
        .then((e) => {
          const linkId = `link_${pendingLink.data.toNodeId.replace(
            'node_',
            ''
          )}_${pendingLink.data.fromNodeId.replace('node_', '')}`;
          const newLink = {
            id: linkId,
            from: {
              nodeId: pendingLink.data.fromNodeId,
              portId: pendingLink.data.fromPortId,
            },
            to: {
              nodeId: pendingLink.data.toNodeId,
              portId: pendingLink.data.toPortId,
            },
            component: 'ConnectionCard',
            props: {
              header: {
                title: {
                  text: 'Connection through',
                },
                buttons: [
                  {
                    icon: {
                      icon: 'trash',
                    },
                    color: 'assertive',
                    action: () => {
                      this.deleteLink(linkId);
                    },
                  },
                  {
                    icon: {
                      icon: 'edit',
                    },
                    color: 'positive',
                    action: () => {
                      this.editLink(linkId);
                    },
                  },
                ],
              },
              label: {
                text: 'activation condition',
              },
              badges: [
                {
                  values: data,
                },
              ],
            },
          };
          delete links[pendingLink.id];
          links[newLink.id] = newLink;
          nodes[linkId] = this.addNodeFromLink(newLink, newLink.id);
          this.setState({
            links,
            nodes,
            pendingLink: null,
          });
          this.resetLinkClick();
        })
        .catch((e) => {
          console.log(e);
          delete links[pendingLink.id];
          this.setState({
            links,
            pendingLink: null,
          });
          this.resetLinkClick();
          alert('Something went wrong');
        });
    }
  };

  editLink = (linkId) => {
    const { nodes, pendingLink } = this.state;
    this.setState({
      modalContent: nodes ? nodes[pendingLink ? pendingLink.id : linkId] : {},
      modalVisible: true,
      pendingLink: {
        update: true,
        id: linkId,
      },
    });
  };

  updateLink = (data, status) => {
    if (status != 'save') {
      this.setState({
        pendingLink: null,
      });
      return;
    }
    const { nodes, pendingLink, subset, actions } = this.state;
    const node = nodes[pendingLink.id];
    const condition = data.map((item) => item.content.text);
    const postData = {
      subset_rule: {
        subset_id: subset,
        evaluation_attributes: {
          condition: condition.join(' '),
        },
      },
    };
    node.props.badges[0].values = data;
    const id = node.id.split('_');
    callApi(
      `${actions.save_position.replace('/0', `/${id[1]}`)}`,
      postData,
      'PATCH'
    )
      .then((e) => {
        this.resetLinkClick();
        this.setState({
          nodes: nodes,
          pendingLink: null,
        });
      })
      .catch((e) => {
        console.log(e);
        this.resetLinkClick();
        this.setState({
          nodes: nodes,
          pendingLink: null,
        });
        alert('Something went wrong');
      });
  };

  deleteLink = (linkId) => {
    const { links } = this.state;
    if (window.confirm('Are you sure you want to delete this condition?')) {
      const { nodes, links, subset, actions } = this.state;
      const node = nodes[linkId];

      const postData = {
        subset_rule: {
          subset_id: subset,
          evaluation_attributes: {
            condition: '',
          },
        },
      };
      node.props.badges[0].values = [];
      const id = node.id.split('_');
      callApi(
        `${actions.save_position.replace('/0', `/${id[1]}`)}`,
        postData,
        'PATCH'
      )
        .then((e) => {
          this.resetLinkClick();
          delete links[linkId];

          this.setState({
            links: links,
            pendingLink: null,
          });
        })
        .catch((e) => {
          console.log(e);
          this.resetLinkClick();
          this.setState({
            links: links,
            pendingLink: null,
          });
          alert('Something went wrong');
        });
    }
  };

  resetLinkClick = () => {
    if (this.state.linkId) {
      const node = this.state.nodes[this.state.linkId];
      if (node) {
        const { nodes } = this.state;
        nodes[this.state.linkId].hidden = true;
        this.setState({
          linkId: null,
        });
      }
    }
  };

  resetPreviewClick = () => {
    if (this.state.previewId) {
      const node = this.state.nodes[this.state.previewId];
      if (node) {
        const { nodes } = this.state;
        nodes[this.state.previewId].hidden = true;
        this.setState({
          previewId: null,
        });
      }
    }
  };

  storeXY = (e) => {
    this.setState({
      clicked: {
        x: e.offsetX,
        y: e.offsetY,
      },
    });
  };

  render() {
    const chart = this.state;
    const { autolayout } = this.props;
    const { initialized } = this.state;
    return (
      <div className={styles.diagramWrapper} ref={this.wrapper}>
        {/* Start diagram */}
        {(!autolayout || initialized) && (
          <FlowChart
            chart={chart}
            callbacks={this.stateActions}
            Components={{
              CanvasOuter: CustomCanvas,
              Node: CustomNode,
              NodeInner: CustomNodeInner,
              Port: CustomPort,
            }}
            config={{
              deleteNode: (node) => {
                this.deleteNode(node);
              },
              previewNode: (node) => {
                this.previewNode(node);
              },
              validateLink: (node) => {
                this.validateLink(node);
              },
            }}
          />
        )}
        {/* End diagram */}

        {/* Start fixed footer with actions */}
        <Wrapper className={styles.footer} size={'xs'}>
          <Button
            outline
            text={'Create new rule'}
            icon={{ icon: 'edit', width: 10, height: 10 }}
            action={this.state.actions.new_rule}
            color={'stable-100'}
            textColor={'positive'}
            customData={{ target: '_blank' }}
          />
          <Button
            outline
            text={'Add existing rule'}
            icon={{ icon: 'plus', width: 10, height: 10 }}
            action={() => {
              this.setState({
                modalVisible: true,
                addRule: true,
              });
            }}
            color={'stable-100'}
            textColor={'positive'}
          />
        </Wrapper>
        {/* End fixed footer with actions */}

        {/* Start modal for rules/conditions */}
        <ActivationModal
          isOpen={this.state.modalVisible}
          select={this.state.addRule}
          title={{
            text: this.state.addRule ? 'Add rule' : 'Activation condition',
          }}
          content={this.state.modalContent}
          completions={this.props.completions}
          onRequestClose={(status, data) => {
            if (this.state.addRule && status == 'save') {
              this.addNode(data);
            }
            if (this.state.pendingLink && this.state.pendingLink.id) {
              if (this.state.pendingLink.update) {
                this.updateLink(data, status);
              } else {
                this.addLink(data, status);
              }
            }

            this.setState({
              modalContent: null,
              modalVisible: false,
              addRule: false,
            });
          }}
        />
        {/* End modal for rules/conditions */}
      </div>
    );
  }
}

Diagram.propTypes = {
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** The data to be used for this diagram. Needs to follow a specific structure, please see data.json */
  data: PropTypes.shape({}),
  /** Whether the cards should be rendered via dagre, only needed when never dragged */
  autolayout: PropTypes.bool,
};

Diagram.defaultProps = {
  className: '',
  data: {},
  autolayout: false,
};

// Needed for Storybook
Diagram.displayName = 'Diagram';

export default Diagram;
