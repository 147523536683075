import React from 'react';
import Divider from '@components/ui/divider';
import Breadcrumbs from '.';

export default {
  title: 'BRMS/layout/Breadcrumbs',
  component: Breadcrumbs,
};

const Template = (args) => <Breadcrumbs {...args} />;

export const Default = Template.bind({});
Default.args = {
  items: [
    {
      action: '#',
      text: 'Level 1',
    },
    {
      action: '#',
      text: 'Level 2',
    },
    {
      action: '#',
      text: 'Level 3',
    },
    {
      action: '#',
      text: 'Level 4',
    },
    {
      action: '#',
      text: 'Level 5',
    },
  ],
};

const items = [
  {
    action: '#',
    text: 'Level 1',
  },
  {
    action: '#',
    text: 'Level 2',
  },
  {
    action: '#',
    text: 'Level 3',
  },
  {
    action: '#',
    text: 'Level 4',
  },
  {
    action: '#',
    text: 'Level 5',
  },
];

export const Lenghts = () => (
  <>
    {items.map((item, i) => {
      const mutatedItems = items.slice(-i).map((x, y) => {
        return { text: `level ${y + 1}` };
      });
      return (
        <>
          <Breadcrumbs items={mutatedItems} />
          <Divider height={20} color={'transparent'} />
        </>
      );
    })}
  </>
);
