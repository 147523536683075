import React from 'react';
import PropTypes from 'prop-types';
import * as Cards from '@components/card';

// Compare on node level, to much mutated date elsewise
const checkEqual = (prev, next) => prev.node === next.node;
const CustomNodeInner = ({ node, otherProps, config }) => {
  const componentType = node.properties?.component || node.component;
  const Component = Cards[componentType];
  const properties = node.properties ? node.properties.props : node.props;
  if (componentType === 'QuestionCard') {
    properties.buttons = [];
    if (node.loading) {
      properties.buttons.push({
        icon: {
          icon:
            node.loading == 'success'
              ? 'save'
              : node.loading == 'error'
              ? 'save'
              : 'save',
        },
        color:
          node.loading == 'success'
            ? 'energized'
            : node.loading == 'error'
            ? 'assertive'
            : 'balanced-500',
        disabled: true,
      });
    }
    if (node.url) {
      properties.buttons.push({
        icon: {
          icon: 'edit',
        },
        color: 'positive',
        action: node.url,
        customData: { target: '_blank' },
      });
    }
    properties.buttons.push({
      icon: {
        icon: 'trash',
      },
      color: 'assertive',
      action: () => config.deleteNode(node),
    });
    if (node.preview) {
      properties.buttons.push({
        icon: {
          icon: 'eye-round',
        },
        color: 'positive',
        action: () => config.previewNode(node),
      });
    }
  } else if (componentType === 'TerminationCard') {
    properties.buttons = [
      {
        icon: {
          icon: 'trash',
        },
        color: 'assertive',
        action: () => config.deleteNode(node),
      },
    ];
  }
  return (
    <div {...otherProps}>
      <Component {...properties} diagram />
    </div>
  );
};
CustomNodeInner.propTypes = {
  node: PropTypes.shape({
    properties: PropTypes.shape({
      type: PropTypes.string,
      child: PropTypes.bool,
      component: PropTypes.string,
      props: PropTypes.shape({}),
    }),
    props: PropTypes.shape({}),
    component: PropTypes.string,
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  otherProps: PropTypes.shape({}),
  config: PropTypes.shape({}),
};

CustomNodeInner.defaultProps = {
  otherProps: {},
  config: {},
};

export default React.memo(CustomNodeInner, checkEqual);
