import React from 'react';
import PropTypes from 'prop-types';
import Element from '@components/form/element';
import Title from '@components/text/title';
import Badge from '@components/badge/badge';
import Divider from '@components/ui/divider';
import Wrapper from '@components/card/wrapper';
import Base from '../base';
import styles from '../tooltip.module.scss';

const Collection = ({ title, content, ...props }) => (
  <Base
    {...props}
    content={
      <Wrapper size={'s'} color={'light'}>
        {title && <Title text={title} size={'h4'} />}
        <Divider height={15} color={'transparent'} />
        <Element
          type={'checkbox'}
          column
          className={styles.list}
          options={content.length > 4 ? content.slice(0, 4) : content}
        />
        {content.length > 4 && (
          <Badge
            content={{ text: `+${content.length - 4} more` }}
            color={'balanced-100'}
            className={styles.badge}
          />
        )}
      </Wrapper>
    }
  />
);

Collection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({})),
};

Collection.defaultProps = {
  title: '',
  content: [],
};

// Needed for Storybook
Collection.displayName = 'Collection';

export default Collection;
