import React from 'react';
import DraggableTable from '.';

export default {
  title: 'BRMS/table/Draggable',
  component: DraggableTable,
};

const Template = (args) => (
  <>
    <DraggableTable {...args} />
  </>
);

export const Default = Template.bind({});
Default.args = {
  sizes: [50, 20, 20, 5],
  head: [
    {
      action: '?q=x&y=z',
      text: 'Rules',
    },
    {
      action: '?q=x&y=z',
      text: 'Kind',
    },
    {
      action: '?q=x&y=z',
      text: 'Category',
    },
    {},
  ],
  body: [
    {
      data: [
        {
          action: 'url',
          text: 'The Phantom Menace',
        },
        {
          text: 'Qui-Gon Jinn',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
    {
      data: [
        {
          text: 'Attack of the Clones',
        },
        {
          text: 'Obi Wan Kenobi',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
    {
      data: [
        {
          text: 'Revenge of the Sith',
        },
        {
          text: 'Anakin Skywalker',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
    {
      data: [
        {
          text: 'A New Hope',
        },
        {
          text: 'Luke Skywalker',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
    {
      data: [
        {
          text: 'The Empire Strikes Back',
        },
        {
          text: 'Leia Organa',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
    {
      data: [
        {
          text: 'Return of the Jedi',
        },
        {
          text: 'Han Solo',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
  ],
};
