import React from 'react';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';
import Timeline from '.';

export default {
  title: 'BRMS/progress/Timeline',
  component: Timeline,
};

const Template = (args) => <Timeline {...args} />;

export const Default = Template.bind({});
Default.args = {
  steps: [
    {
      detail: {
        label: {
          text: 'Added new answers',
        },
        values: [
          {
            text: 'Edited rule',
          },
          {
            text: 'what_illness.',
            action: '#',
          },
          {
            text: 'This affected subsets',
          },
          {
            text: 'basic questionaire',
            action: '#',
          },
          {
            text: 'and',
          },
          {
            text: 'illness',
            action: '#',
          },
        ],
      },
      date: {
        text: '30-10-2020 9:45 AM',
      },
      from: {
        text: 'Remark',
      },
      status: 'unpublished',
    },
    {
      detail: {
        label: {
          text: 'Changed order of rules',
        },
        value: {
          text: 'Edited subset basic questionaire',
        },
      },
      date: {
        text: '08-07-2020, 2:35PM',
      },
      from: {
        text: 'Remark',
      },
      status: 'unpublished',
    },
    {
      badge: {
        content: {
          text: 'Published',
        },
        color: 'positive',
      },
      date: {
        text: '08-07-2020, 2:35PM',
      },
      from: {
        text: 'Remark',
      },
      status: 'published',
    },
    {
      detail: {
        label: {
          text: 'Updated rule title',
        },
        value: {
          text:
            'Edited rule what_illness. This affected subsets basic questionaire and illness',
        },
      },
      date: {
        text: '30-10-2020 9:45 AM',
      },
      from: {
        text: 'Remark',
      },
      status: 'published',
    },
    {
      detail: {
        label: {
          text: 'Updated rule title',
        },
        value: {
          text:
            'Edited rule what_illness. This affected subsets basic questionaire and illness',
        },
      },
      date: {
        text: '30-10-2020 9:45 AM',
      },
      from: {
        text: 'Remark',
      },
      status: 'published',
    },
    {
      detail: {
        label: {
          badge: {
            content: {
              text: 'Updated from master',
            },
            color: 'royal',
            size: 'm',
          },
        },
        value: {
          text:
            'Edited rule what_illness. This affected subsets basic questionaire and illness',
        },
      },
      date: {
        text: '08-07-2020, 2:35PM',
      },
      from: {
        text: 'Remark',
      },
      status: 'published',
    },
    {
      detail: {
        label: {
          text: 'Added new answers',
        },
        value: {
          text:
            'Edited rule what_illness. This affected subsets basic questionaire and illness',
        },
      },
      date: {
        text: '30-10-2020 9:45 AM',
      },
      from: {
        text: 'Remark',
      },
      status: 'published',
    },
  ],
};

export const WithValues = () => (
  <Timeline
    steps={[
      {
        detail: {
          label: {
            text: 'Ready for review',
          },
          values: [
            {
              text: 'Edited rule',
            },
            {
              text: 'what_illness.',
              action: '#',
            },
            {
              text: 'This affected subsets',
            },
            {
              text: 'basic questionaire',
              action: '#',
            },
            {
              text: 'and',
            },
            {
              text: 'illness',
              action: '#',
            },
          ],
        },
        date: {
          text: '08-07-2020, 2:35PM',
        },
      },
      {
        detail: {
          label: {
            text: 'Draft',
          },
          date: {
            text: '08-07-2020, 2:35PM',
          },
        },
        status: 'done',
      },
    ]}
  />
);

export const WithDate = () => (
  <Timeline
    steps={[
      {
        detail: {
          label: {
            text: 'Ready for review',
          },
        },
        date: {
          text: '08-07-2020, 2:35PM',
        },
      },
      {
        detail: {
          label: {
            text: 'Draft',
          },
          date: {
            text: '08-07-2020, 2:35PM',
          },
        },
        status: 'done',
      },
    ]}
  />
);

export const Progress = () => (
  <Timeline
    {...Default.args}
    type={'progress'}
    steps={[
      {
        detail: {
          label: {
            text: 'Accepted or declined',
          },
          value: {
            text: '',
          },
        },
        status: 'open',
      },
      {
        detail: {
          label: {
            text: 'Under review',
          },
          value: {
            text:
              'Edited rule what_illness. This affected subsets basic questionaire and illness',
          },
        },
        status: 'active',
      },
      {
        detail: {
          label: {
            text: 'Ready for review',
          },
          value: {
            text:
              'Edited rule what_illness. This affected subsets basic questionaire and illness',
          },
        },
        status: 'done',
      },
      {
        detail: {
          label: {
            text: 'Draft',
          },
          value: {
            text:
              'Edited rule what_illness. This affected subsets basic questionaire and illness',
          },
        },
        status: 'done',
      },
    ]}
  />
);

export const Unpublished = () => <Timeline endless {...Default.args} />;

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      const bgColor =
        color == 'positive-300'
          ? `rgba(var(--color-${color}), 0.15)`
          : `rgba(var(--color-${color}), 1)`;
      return (
        <div key={index}>
          <div style={{ backgroundColor: bgColor }}>
            <Divider height={15} color={'transparent'} />
            <Timeline {...Default.args} color={color} />
          </div>
          <Divider height={15} color={'transparent'} />
        </div>
      );
    })}
  </>
);
