import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Button from '@components/button/button';
import IconLeft from '@components/button/icon-left';
import IconRight from '@components/button/icon-right';
import Badge from '@components/badge/badge';
import Title from '@components/text/title';
import Divider from '@components/ui/divider';
import Tooltip from '@components/ui/tooltip/base';
import styles from './element.module.scss';
import Simple from '../simple';
import Textarea from '../textarea';
import Group from '../group';
import Option from '../option';
import Datepicker from '../datepicker';
import Select from '../select';
import AsynchronousSelect from '../asynchronousSelect';
import File from '../file';
import RadioSelect from '../radioSelect';

const cx = classNames.bind(styles);

const Element = (props) => {
  const classes = cx(
    {
      element: true,
      inline: props.inline,
      inlineReverse: props.inlineReverse,
      background: props.background,
      error: props.errors && props.errors.length > 0,
      column: props.column,
      remove: props.remove,
    },
    props.className
  );
  const inputClasses = cx({
    flat: props.flat,
    input: !['checkbox', 'radio', 'button', 'submit', 'file'].includes(
      props.type
    ),
    inputFont: true,
  });
  const extraProps = {};
  const identifier = props.identifier
    ? props.identifier
    : Math.random().toString(36).substr(2, 9);
  const InputElement = ['button', 'submit'].includes(props.type)
    ? props.iconRight
      ? IconRight
      : props.icon || props.iconLeft
      ? IconLeft
      : Button
    : ['datepicker'].includes(props.type)
    ? Datepicker
    : ['select'].includes(props.type)
    ? Select
    : ['asynchronous-select'].includes(props.type)
    ? AsynchronousSelect
    : ['radio', 'checkbox'].includes(props.type)
    ? props.options
      ? Group
      : Option
    : props.options && props.button
    ? RadioSelect
    : props.options && ['radio-select'].includes(props.type)
    ? RadioSelect
    : ['textarea'].includes(props.type)
    ? Textarea
    : ['file'].includes(props.type)
    ? File
    : Simple;
  if (['button', 'submit'].includes(props.type)) {
    extraProps.color = 'positive-300';
    extraProps.size = 'l';
    extraProps.icon = props.iconRight || props.iconLeft || props.icon;
  }
  if (['datepicker'].includes(props.type)) {
    extraProps.color = 'positive';
  }
  if (['select'].includes(props.type)) {
    extraProps.className = 'inputSelect';
  }

  const _customDataToAttr = (obj) =>
    Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value]));
  const attributes = props.customData
    ? _customDataToAttr(props.customData)
    : [];
  return (
    <div className={classes}>
      {(props.label || props.errors) && (
        <label htmlFor={identifier} className={styles.label}>
          {props.label && (
            <Title
              text={props.label}
              size={'label'}
              inline
              color={
                props.errors && props.errors.length > 0
                  ? 'assertive'
                  : 'balanced-500'
              }
            />
          )}
          {props.required && (
            <Title
              text={'*'}
              size={'label'}
              inline
              color={
                props.errors && props.errors.length > 0
                  ? 'assertive'
                  : 'balanced-500'
              }
            />
          )}
          {props.tooltip && <Divider width={5} color={'transparent'} />}
          {props.tooltip && (
            <Tooltip
              button={{
                text: '?',
                rounded: true,
                size: 'xs',
                color: 'balanced-500',
                outline: true,
                className: styles.tooltip,
              }}
              trigger={'mouseenter'}
              simple
              {...props.tooltip}
            />
          )}
          {props.errors &&
            props.errors.map((error, index) => (
              <Badge
                key={index}
                color={'assertive'}
                size={'s'}
                className={styles.badge}
                content={
                  typeof error === 'object'
                    ? error
                    : {
                        text: error,
                      }
                }
              />
            ))}
          {props.labelAction && (
            <a
              href={props.labelAction.action}
              className={styles.labelAction}
              tabIndex={2}
            >
              <Title
                text={props.labelAction.text}
                color={'positive'}
                size={'label'}
              />
            </a>
          )}
        </label>
      )}
      {props.label && !props.background && (
        <Divider
          width={props.inline ? 15 : 0}
          height={props.inline ? 0 : 5}
          color={'transparent'}
        />
      )}{' '}
      {props.remove ? (
        <div className={styles.removeWrapper}>
          <InputElement
            id={identifier}
            className={inputClasses}
            {...extraProps}
            {...props}
            attributes={attributes}
          />
          <Button
            outline
            color={'stable-500'}
            icon={{ icon: 'cross', color: 'balanced-500' }}
            className={styles.removeButton}
            {...props.remove}
          />
        </div>
      ) : (
        <InputElement
          id={identifier}
          className={inputClasses}
          {...extraProps}
          {...props}
          attributes={attributes}
        />
      )}
    </div>
  );
};

Element.propTypes = {
  /** The input type */
  type: PropTypes.oneOf([
    'text',
    'password',
    'tel',
    'email',
    'url',
    'textarea',
    'datepicker',
    'select',
    'checkbox',
    'radio',
    'button',
    'submit',
    'file',
    'hidden',
    'number',
    'asynchronous-select',
  ]).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  /** True when the label should be placed before the input */
  inline: PropTypes.bool,
  /** The label text */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** Whether the input is required */
  required: PropTypes.bool,
  /** Whether the group is a column */
  column: PropTypes.bool,
  /** Whether the input has a remove button */
  remove: PropTypes.shape(Button.propTypes),
};

Element.defaultProps = {
  type: 'text',
  name: 'default',
};

// Needed for Storybook
Element.displayName = 'Element';

export default Element;
