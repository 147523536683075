import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import { _textColor } from '@components/styles';
import IconLeft from '../icon-left';
import styles from './tab.module.scss';

const cx = classNames.bind(styles);

const Tab = (props) => {
  const classes = cx(
    {
      tab: true,
      active: props.active,
    },
    props.className
  );
  const dividerColor = props.textColor
    ? props.textColor
    : props.outline || props.link
    ? props.color
    : _textColor(props.color);

  return (
    <div className={classes}>
      <Divider height={4} color={'transparent'} />
      <IconLeft
        textColor={props.active ? dividerColor : props.textColor}
        className={styles.button}
        {...props}
      />
      <Divider
        height={4}
        color={props.active ? dividerColor : 'transparent'}
        margin={[10, 0, 0, 0]}
        style={{ opacity: 1 }}
      />
    </div>
  );
};

Tab.propTypes = {
  /** Enables the active state of the tab */
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** All button props are allowed */
  button: PropTypes.shape(IconLeft.propTypes),
};

Tab.defaultProps = {
  active: false,
  button: {},
};

// Needed for Storybook
Tab.displayName = 'Tab';

export default Tab;
