import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import Count from '.';

export default {
  title: 'BRMS/badge/Count',
  component: Count,
};

const Template = (args) => <Count {...args} />;

export const Default = Template.bind({});
Default.args = {
  title: {
    text: '1',
  },
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <div key={index} style={{ display: 'flex' }}>
          <Count {...Default.args} color={color} />
        </div>
      );
    })}
  </>
);
export const Sizes = () => (
  <>
    <Count {...Default.args} size={'s'} />
    <Count {...Default.args} size={'m'} />
    <Count {...Default.args} size={'l'} />
  </>
);
export const Variants = () => (
  <>
    <Count {...Default.args} size={'s'} />
    <Count {...Default.args} size={'s'} round />
    <Count {...Default.args} size={'s'} round lighten />
    <Count {...Default.args} size={'m'} />
    <Count {...Default.args} size={'m'} round />
    <Count {...Default.args} size={'m'} round lighten />
    <Count {...Default.args} size={'l'} />
    <Count {...Default.args} size={'l'} round />
    <Count {...Default.args} size={'l'} round lighten />
  </>
);
