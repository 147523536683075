// return color variable based on input color
const _textColor = (color) => {
  return ['stable-100', 'stable-300', 'stable-500', 'transparent'].includes(
    color
  )
    ? 'balanced-500'
    : ['light', 'transparent'].includes(color)
    ? 'positive'
    : 'light';
};

export { _textColor };
