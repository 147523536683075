import React from 'react';
import Datepicker from '.';

export default {
  title: 'BRMS/form/Datepicker',
  component: Datepicker,
  parameters: {
    componentSubtitle:
      'Uses React-Flatpickr (https://github.com/haoxins/react-flatpickr) under the hood',
  },
};

const Template = (args) => (
  <>
    <Datepicker {...args} />
  </>
);
export const Default = Template.bind({});

Default.args = {
  id: 'datepicker',
};
