import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import IconRight from '.';

export default {
  title: 'BRMS/button/IconRight',
  component: IconRight,
};

const Template = (args) => <IconRight {...args} />;

export const Default = Template.bind({});
Default.args = {
  text: 'IconRight',
  icon: { icon: 'empty' },
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconRight
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          {...Default.args}
        />
      );
    })}
  </>
);

export const Outline = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconRight
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          outline
          {...Default.args}
        />
      );
    })}
  </>
);

export const Link = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconRight
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          link
          {...Default.args}
        />
      );
    })}
  </>
);

export const Size = () => (
  <>
    <IconRight text={'Small'} size={'s'} {...Default.args} />
    <IconRight text={'Medium'} size={'m'} {...Default.args} />
    <IconRight text={'Large'} size={'l'} {...Default.args} />
  </>
);

export const Rounded = () => (
  <>
    <IconRight text={'Rounded Small'} rounded size={'s'} {...Default.args} />
    <IconRight text={'Rounded Medium'} rounded size={'m'} {...Default.args} />
    <IconRight
      text={'Rounded Medium'}
      rounded
      color={'assertive'}
      size={'m'}
      {...Default.args}
    />
    <IconRight
      text={'Rounded Medium'}
      rounded
      color={'energized'}
      size={'m'}
      {...Default.args}
    />
    <IconRight text={'Rounded Large'} rounded size={'l'} {...Default.args} />
  </>
);
