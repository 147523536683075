import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactSelect from 'react-select';
import Title from '@components/text/title';
import styles from './select.module.scss';
import deburr from 'lodash.deburr';

const Rails = typeof window !== 'undefined' ? require('@rails/ujs') : null;

const cx = classNames.bind(styles);

const Select = ({
  options,
  id,
  type,
  name,
  disabled,
  placeholder,
  multi,
  attributes,
  data,
  input,
  onChange,
  noClose,
  limit,
}) => {
  const shouldIClose = noClose ? false : !attributes || !attributes.form;
  const [error, setError] = useState('');
  const [localOptions, setLocalOptions] = useState(
    options?.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    })
  );
  const [localValue, setValue] = useState(
    options.filter((prop) => prop.checked)
  );
  const classes = cx({
    select: true,
  });

  const changeValue = (action) => {
    setValue(action);
    if (!shouldIClose) return;
    if (input && typeof input === 'function') input();
    if (data && Object.entries(data).length > 0) {
      const elem = document.querySelector(`[name="${action.name}"]`);
      elem.setAttribute('value', action.value);
      Object.entries(data).forEach((entry) => {
        elem.setAttribute(`data-${entry[0]}`, entry[1]);
      });
      Rails.fire(elem, 'change');
    }
    if (typeof onChange === 'function') {
      onChange(action, 'select');
    }
  };

  // const onClose = () => {
  //   if (!shouldIClose && multi && onChange && typeof onChange === 'function') {
  //     onChange(localValue, 'select');
  //   }
  // };

  useEffect(() => {
    if (localValue.length === 0) {
      setValue(options.filter((prop) => prop.checked));
    }
    setLocalOptions(
      options?.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
    );
  }, [options]);

  useEffect(() => {
    if (limit) setLocalOptions(localOptions.splice(0, limit));
  }, []);

  const filterOptions = (e) => {
    if (e.length !== 0) {
      const originalOptions = [...options]
        .filter((v) =>
          deburr(v.value.toUpperCase()).includes(deburr(e.toUpperCase()))
        )
        .splice(0, limit);
      setLocalOptions(originalOptions);
      if (limit === originalOptions.length) {
        setError('Please provide 3 or more characters');
      }
    } else setError('');
  };

  return (
    <>
      {error && (
        <Title text={error} size={'label'} inline color={'assertive'} />
      )}
      <ReactSelect
        id={id}
        className={classes}
        classNamePrefix={'select'}
        // set captureMenuScroll to false to prevent:
        // non-passive event listener in scroll blocking events
        captureMenuScroll={false}
        type={type}
        name={name}
        isDisabled={disabled}
        placeholder={placeholder}
        options={localOptions}
        isMulti={multi}
        value={localValue}
        form={attributes ? attributes.form : ''}
        closeMenuOnSelect={shouldIClose}
        onInputChange={limit && filterOptions}
        // onMenuClose={onClose}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
          }),
        }}
        menuPlacement={'auto'}
        onChange={(action, actionType) => {
          changeValue(action, actionType);
        }}
      />
    </>
  );
};

Select.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['select']),
  /** The name of the input */
  name: PropTypes.string,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
  /** Ability to close */
  noClose: PropTypes.bool,
  /** Options to chose from */
  options: PropTypes.arrayOf(PropTypes.shape({})),
  /** Allows multiple answers */
  multi: PropTypes.bool,
  /** Allows extra attributes */
  attributes: PropTypes.arrayOf(PropTypes.shape({})),
  /** Additional data */
  data: PropTypes.shape({}),
  /** Input funcitonality */
  input: PropTypes.func,
  /** onChange functionality */
  onChange: PropTypes.func,
  /** Limit the returned options */
  limit: PropTypes.number,
};

Select.defaultProps = {
  attributes: {},
  data: null,
  disabled: false,
  input: null,
  limit: null,
  multi: false,
  name: 'asynchronous-select',
  noClose: false,
  onChange: null,
  options: [],
  placeholder: '',
  type: 'asynchronous-select',
};

// Needed for Storybook
Select.displayName = 'Select';

export default Select;
