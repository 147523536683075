import React from 'react';
import { Divider } from '@components/ui';
import {
  Colors as ColorNames,
  Sizes as SizeNames,
} from '@components/variables';
import Content from '.';

export default {
  title: 'BRMS/text/Content',
  component: Content,
};

const Template = (args) => <Content {...args} />;
const defaultContent =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend metus non mauris tempus finibus eget nec ipsum. Vivamus dapibus molestie sem sed suscipit.';

export const Default = Template.bind({});
Default.args = {};

export const Alignment = () => (
  <>
    <Content text={defaultContent} />
    <Divider height={15} color={'light'} />
    <Content text={defaultContent} align={'center'} />
    <Divider height={15} color={'light'} />
    <Content text={defaultContent} align={'right'} />
  </>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <div key={index}>
          <Content text={defaultContent} color={color} />
          <Divider height={15} color={'light'} />
        </div>
      );
    })}
  </>
);

export const Sizes = () => (
  <>
    {SizeNames.map((size, index) => {
      return (
        <div key={index}>
          <Content text={defaultContent} size={size} />
          <Divider height={15} color={'light'} />
        </div>
      );
    })}
  </>
);
