import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import axios from 'axios';
import AceEditor from 'react-ace';
import Tooltip from '@components/ui/tooltip';
import Icon from '@components/ui/icon';
import Badge from '@components/badge/badge';
import Title from '@components/text/title';
import Divider from '@components/ui/divider';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/theme-github';
import {
  addCompleter,
  setCompleters,
} from 'ace-builds/src-noconflict/ext-language_tools';
import Element from '../element';
import editorStyles from './codeEditor.module.scss';
import styles from '../element/element.module.scss';

const cx = classNames.bind({ ...styles, ...editorStyles });

const CodeEditor = ({
  completions,
  functions,
  initialValue,
  inputName,
  url,
  label,
  method,
  autoFocus,
  tooltip,
  validator,
  initialErrors,
  setValidity,
  setContent,
  storybook,
  rowCount,
}) => {
  const editor = useRef();
  const [errors, setErrors] = useState(initialErrors);
  const [initialised, setInitialised] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [localAnnotations, setLocalAnnotations] = useState([]);
  const timeoutId = useRef();
  const classes = cx({
    input: true,
    editor: true,
    error: errors && errors.length > 0,
  });
  let source;

  useEffect(() => {
    setInitialised(true);
    if (autoFocus) {
      const row = editor.current.editor.session.getLength() - 1;
      const column = editor.current.editor.session.getLine(row).length;
      editor.current.editor.gotoLine(row + 1, column);
      editor.current.editor.focus();
    }
  }, [initialValue]);

  const validate = () => {
    /** Catch empty url for storybook */
    if (url === '') return;

    const { session } = editor.current.editor;
    const callback = {
      url,
      method,
    };
    source = axios.CancelToken.source();
    const cancelToken = source.token;

    axios({
      method: 'POST',
      url: callback.url,
      data: `evaluation[condition]=${encodeURIComponent(
        session.doc.$lines.join(' ')
      )}&evaluation[validator]=${validator}`,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      },
      cancelToken,
    }).then((resp) => {
      const ruleErrors = [];
      session.doc.$lines.map((words, index) => {
        words.split(' ').map((word) => {
          if (Object.keys(resp.data.errors).includes(word.replace(/\s/g, ''))) {
            if (ruleErrors.findIndex((x) => x.index === index) === -1) {
              ruleErrors.push({
                word,
                index,
                errors: [
                  {
                    key: word,
                    error: resp.data.errors[word.replace(/\s/g, '')],
                  },
                ],
              });
            }
          }
        });
      });
      const genericErrors = [];
      Object.keys(resp.data.errors).map((error) => {
        if (error === 'syntax' || error === 'ending_token') {
          genericErrors.push(resp.data.errors[error]);
        } else {
          genericErrors.push(`${error}: ${resp.data.errors[error]}`);
        }
      });

      if (setValidity && setContent) {
        if (ruleErrors.length > 0 || genericErrors.length > 0)
          setValidity(false);
        else {
          setValidity(true);
        }
      }
      setErrors(genericErrors);
      setLocalAnnotations(ruleErrors);
    });
  };

  useEffect(() => {
    if (setContent) setContent(value);
    if (!initialised) return;
    if (!storybook && setValidity) setValidity(false);
    clearTimeout(timeoutId.current);
    if (source) source.cancel();
    timeoutId.current = setTimeout(() => {
      validate();
    }, 1000);
  }, [value]);

  useEffect(() => {
    const newCompleter = {
      getCompletions(editor, session, pos, prefix, callback) {
        callback(null, completions);
      },
    };

    const completionValues = completions.map((x) => x.value);

    const functionValues = functions.map((x) => x.name.replace('()', ''));

    const keywordMapper = (v) =>
      v === 'true'
        ? 'constant.true'
        : v === 'false'
        ? 'constant.false'
        : v === 'null'
        ? 'constant.null'
        : functionValues.includes(v)
        ? 'constant.function'
        : completionValues.includes(v)
        ? 'completion'
        : 'identifier';

    const session = editor.current.editor.getSession();
    session.setMode(`ace/mode/text`, () => {
      const rules = session.$mode.$highlightRules.getRules();
      if (Object.prototype.hasOwnProperty.call(rules, 'start')) {
        rules.start = [
          {
            token: 'constant.numeric', // float
            regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b',
          },
          {
            token: 'keyword.operator',
            regex:
              '\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|=',
          },
          {
            token: keywordMapper,
            regex: '[\\.a-zA-Z_$][\\.a-zA-Z0-9_$]*\\b',
          },
        ];
      }
      // force recreation of tokenizer
      session.$mode.$tokenizer = null;
      session.bgTokenizer.setTokenizer(session.$mode.getTokenizer());
      // force re-highlight whole document
      session.bgTokenizer.start(0);
    });

    // to extend existing
    // addCompleter(myCompleter);
    // to override all
    setCompleters([newCompleter]);
  }, [completions]);

  useEffect(() => {
    if (editor && editor.current && editor.current.editor) {
      // Calculate how many characters should be visible
      const maxLength = completions
        .map((completion) => completion.meta.length + completion.name.length)
        .sort()
        .reverse()[0];
      // Initialize completer
      editor.current.editor.execCommand('startAutocomplete');
      // Deactive completer
      editor.current.editor.completer.detach();
      // Set the correct width of the popup
      if (editor.current.editor.completer.popup) {
        editor.current.editor.completer.popup.container.style.width = `${
          maxLength * 10
        }px`;
        editor.current.editor.completer.popup.resize();
      }
    }
  }, []);

  return (
    <div>
      {(label || errors) && (
        <label className={styles.label}>
          {tooltip && (
            <Tooltip
              simple
              trigger={'mouseenter'}
              button={{
                color: 'transparent',
                icon: { icon: 'info' },
              }}
              content={{ text: tooltip }}
            />
          )}
          {label && (
            <Title
              text={label}
              size={'label'}
              inline
              color={errors && errors.length > 0 ? 'assertive' : 'balanced-500'}
            />
          )}
          {errors &&
            errors.map((error, index) => (
              <Badge
                key={index}
                color={'assertive'}
                size={'s'}
                className={styles.badge}
                content={{ text: error }}
              />
            ))}
        </label>
      )}
      {(label || errors) && (
        <Divider width={0} height={5} color={'transparent'} />
      )}
      <div className={editorStyles.wrapper}>
        <AceEditor
          className={classes}
          ref={editor}
          value={value}
          mode='text'
          theme='github'
          onChange={(e) => setValue(e)}
          name='UNIQUE_ID_OF_DIV'
          width='100%'
          height={`${rowCount * 25}px`}
          fontSize={'var(--font-size-l)'}
          fontFamily={'var(--font-stack-mono)'}
          editorProps={{ $blockScrolling: true }}
          highlightSelectedWord
          setOptions={{
            hasCssTransforms: true,
            useWorker: false,
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
          }}
          showGutter
        />
        <div className={editorStyles.errorwrapper}>
          {localAnnotations.map(
            (annotation, i) =>
              annotation.errors.length > 0 && (
                <div
                  key={i}
                  className={editorStyles.errorbar}
                  style={{ top: `${annotation.index * 24}px` }}
                >
                  <Tooltip
                    content={annotation.errors}
                    position='bottom'
                    trigger={'mouseenter'}
                    editor
                  >
                    <Icon
                      icon={'chevron-right'}
                      color={'royal'}
                      width={10}
                      height={10}
                      className={editorStyles.errorChev}
                    />
                  </Tooltip>
                  <div className={editorStyles.errorOverlay} />
                </div>
              )
          )}
        </div>
        <Element type={'hidden'} name={inputName} value={value} />
      </div>
    </div>
  );
};

CodeEditor.propTypes = {
  /* Name of input in form */
  inputName: PropTypes.string,
  /* Default value */
  initialValue: PropTypes.string,
  /* Set of completions that can be suggested by the editor */
  completions: PropTypes.arrayOf(PropTypes.shape({})),
  /* Set the function that are shown with a color by the editor */
  functions: PropTypes.arrayOf(PropTypes.shape({})),
  // Add errors per row
  initialErrors: PropTypes.arrayOf(PropTypes.string),
  // Url for request
  url: PropTypes.string,
  /** The label text */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // Method of request
  method: PropTypes.string,
  /* Whether the editor needs to be autofocussed */
  autoFocus: PropTypes.bool,
  /* Tooltip text for the code editor */
  tooltip: PropTypes.string,
  /* Add custom functionality for Storybook */
  storybook: PropTypes.bool,
  /** Set number of rows */
  rowCount: PropTypes.number,
  /* Validator to be used on validation */
  validator: PropTypes.string,
};

CodeEditor.defaultProps = {
  inputName: '',
  initialValue: '',
  completions: [],
  functions: [],
  initialErrors: [],
  label: '',
  url: '/admin/evaluation_validations',
  method: 'POST',
  autoFocus: false,
  tooltip: '',
  storybook: false,
  validator: 'condition',
  rowCount: 5,
};

CodeEditor.displayName = 'CodeEditor';

export default CodeEditor;
