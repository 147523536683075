import React from 'react';
import { Title } from '../../text';
import RuleCard from '.';

export default {
  title: 'BRMS/card/RuleCard',
  component: RuleCard,
};

const Template = (args) => (
  <>
    <RuleCard {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  header: {
    title: {
      text: 'With header & badge',
    },
    content: {
      text: 'Edit master rule',
    },
    badge: {
      label: {
        badge: {
          content: {
            text: 'True',
          },
        },
      },
      value: {
        text: 'Global',
      },
    },
    button: {
      text: 'Edit rule',
    },
  },
  questionDetails: [
    {
      label: {
        text: 'Rule kind',
      },
      value: {
        text: 'Question',
      },
    },
    {
      label: {
        text: 'Question input',
      },
      value: {
        text: 'Checkboxes',
      },
    },
    {
      label: {
        text: 'Question title',
      },
      value: {
        text: 'What illness do you have?',
      },
    },
    {
      label: {
        text: 'Question help text',
      },
      value: {
        text: 'Please select your illness',
      },
    },
  ],
  title: {
    text: 'Answers',
  },
  answerDetails: [
    {
      label: {
        text: 'Input',
      },
      value: {
        text: 'Manual',
      },
    },
    {
      label: {
        text: 'Answers',
      },
      table: {
        head: [
          {
            text: 'Experience class',
            color: 'light',
          },
          {
            text: 'Name',
            color: 'light',
          },
          {
            text: 'Title',
            color: 'light',
          },
        ],
        body: [
          {
            data: [
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
            ],
          },
          {
            data: [
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
            ],
          },
          {
            data: [
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
            ],
          },
          {
            data: [
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
            ],
          },
          {
            data: [
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
            ],
          },
          {
            data: [
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
              {
                text: 'ill_01',
              },
            ],
          },
        ],
      },
    },
  ],
};
