import React from 'react';
import DraggableCollapsible from '.';

export default {
  title: 'BRMS/table/DraggableCollapsible',
  component: DraggableCollapsible,
};

const Template = (args) => (
  <>
    <DraggableCollapsible {...args} />
  </>
);

export const Default = Template.bind({});
Default.args = {
  sizes: [5, 40, 5, 5],
  bodies: [
    [
      {
        data: [
          {
            url: 'something',
            method: 'PUT',
          },
          {
            action: '/admin/rulesets/2/subsets/12',
            text: 'Subset with subset activation rule',
          },
          { text: '3 rules' },
          {
            action: '/admin/subset_rules/2.12',
            customData: { 'data-method': 'delete' },
            color: 'transparent',
            textColor: 'assertive',
            icon: { icon: 'trash' },
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/198/edit',
            text: 'subset_activation_question_1',
            badges: [],
          },
          {
            action: '/admin/subset_rules/198/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/199/edit',
            text: 'normal_question_1',
            badges: [
              {
                text:
                  'subset_with_subset_activation_rule.subset_activation_question_1',
                type: 'text',
              },
              { text: '!=', type: 'operator' },
              { text: 'null', type: 'boolean' },
            ],
          },
          {
            action: '/admin/subset_rules/199/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/200/edit',
            text: 'subset_activation_question_2',
            badges: [
              {
                text: 'subset_with_subset_activation_rule.normal_question_1',
                type: 'text',
              },
              { text: '!=', type: 'operator' },
              { text: 'null', type: 'boolean' },
            ],
          },
          {
            action: '/admin/subset_rules/200/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
    ],
    [
      {
        data: [
          {
            url: 'something',
            method: 'PUT',
          },
          {
            action: '/admin/rulesets/2/subsets/13',
            text: 'Normal second subset',
          },
          { text: '1 rules' },
          {
            action: '/admin/subset_rules/2.13',
            customData: { 'data-method': 'delete' },
            color: 'transparent',
            textColor: 'assertive',
            icon: { icon: 'trash' },
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/201/edit',
            text: 'normal_question_2',
            badges: [],
          },
          {
            action: '/admin/subset_rules/201/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
    ],
    [
      {
        data: [
          {
            url: 'something',
            method: 'PUT',
          },
          {
            action: '/admin/rulesets/2/subsets/13',
            text: 'Normal second subset',
          },
          { text: '1 rules' },
          {
            action: '/admin/subset_rules/2.13',
            customData: { 'data-method': 'delete' },
            color: 'transparent',
            textColor: 'assertive',
            icon: { icon: 'trash' },
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/201/edit',
            text: 'normal_question_2',
            badges: [],
          },
          {
            action: '/admin/subset_rules/201/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
    ],
    [
      {
        data: [
          {
            url: 'something',
            method: 'PUT',
          },
          {
            action: '/admin/rulesets/2/subsets/13',
            text: 'Normal second subset',
          },
          { text: '1 rules' },
          {
            action: '/admin/subset_rules/2.13',
            customData: { 'data-method': 'delete' },
            color: 'transparent',
            textColor: 'assertive',
            icon: { icon: 'trash' },
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/201/edit',
            text: 'normal_question_2',
            badges: [],
          },
          {
            action: '/admin/subset_rules/201/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
    ],
  ],
};
