import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../table/table.module.scss';
import componentStyles from './collapsible.module.scss';
import { BodyRow, FlatRow, HeadRow } from '../_row';

const cx = classNames.bind(styles);

const Collapsible = ({
  bodies,
  className,
  color,
  head,
  inverse,
  params,
  sizes,
}) => {
  const columns =
    head && head.length > 0
      ? head.length
      : bodies
      ? bodies[0][0].data.length
      : 0;
  const classes = cx(
    {
      table: true,
      collapsed: true,
      inverse,
    },
    className,
    color
  );

  const [visible, setVisible] = useState([]);

  const toggleRows = (index) => {
    if (visible.includes(index)) {
      setVisible(visible.filter((i) => i != index));
    } else {
      setVisible([...visible, index]);
    }
    document.activeElement.blur();
  };
  const _renderHead = (data) => (
    <tr>
      {data.map((column, i) => (
        <HeadRow
          key={i}
          column={column}
          params={params}
          size={i < sizes.length ? sizes[i] : 5}
        />
      ))}
      <HeadRow column={{}} params={params} size={5} />
    </tr>
  );

  const _renderRow = (row, bodyIndex) => (
    <tr
      className={
        visible.includes(bodyIndex)
          ? `${componentStyles.row} ${componentStyles.open}`
          : componentStyles.row
      }
    >
      {row.map((column, i) => {
        const colspan =
          row.length < columns ? (i === 0 ? columns - row.length + 2 : 1) : 1;
        return (
          <BodyRow
            key={i}
            index={bodyIndex}
            colspan={colspan}
            column={column}
            size={i < sizes.length ? sizes[i] : 5}
          />
        );
      })}
      <BodyRow
        index={bodyIndex}
        column={{
          link: true,
          color: 'transparent',
          action: () => toggleRows(bodyIndex),
          icon: {
            icon: visible.includes(bodyIndex) ? 'chevron-up' : 'chevron-down',
            width: 10,
            color: 'dark',
          },
          className: componentStyles.lastCellBtn,
        }}
        size={5}
      />
    </tr>
  );

  const _renderHiddenRow = (row, index, final) => (
    <tr key={index} className={componentStyles.row}>
      {row.map((column, i) => {
        const colspan =
          row.length < columns ? (i === 0 ? columns - row.length + 2 : 1) : 1;
        return (
          <FlatRow
            key={i}
            colspan={colspan}
            column={column}
            last={i === row.length - 1}
            final={final}
          />
        );
      })}
    </tr>
  );

  return (
    <table className={classes} cellSpacing='0' cellPadding='0'>
      {head && <thead>{_renderHead(head, true)}</thead>}
      {bodies &&
        bodies.map((body, bodyIndex) => {
          const firstRow = body.slice(0, 1).map((r) => r.data);
          const hiddenRows = body.slice(1, body.length).map((r) => r.data);
          return (
            <tbody key={bodyIndex}>
              {_renderRow(firstRow[0], bodyIndex)}
              {visible.includes(bodyIndex) &&
                hiddenRows.map((row, index) =>
                  _renderHiddenRow(row, index, index === row.length - 1)
                )}

              <tr>
                <td
                  className={componentStyles.hack}
                  colSpan={firstRow[0].length + 1}
                />
              </tr>
            </tbody>
          );
        })}
    </table>
  );
};

Collapsible.propTypes = {
  bodies: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  head: PropTypes.arrayOf(PropTypes.shape({})),
  inverse: PropTypes.bool,
  params: PropTypes.shape({}),
  sizes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Collapsible.defaultProps = {
  className: '',
  color: '',
  head: [],
  inverse: false,
  params: {},
};

// Needed for Storybook
Collapsible.displayName = 'Collapsible';

export default Collapsible;
