import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors } from '@components/variables';
import sprite from './sprite.svg';
import styles from './icon.module.scss';

const cx = classNames.bind(styles);
const Icon = ({ color, className, width, height, icon, ...props }) => {
  const classes = cx(
    {
      icon: true,
    },
    color,
    className
  );

  return (
    <svg
      className={classes}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio='xMidYMid meet'
      style={{
        width,
        height,
      }}
      {...props}
    >
      <use xlinkHref={`${sprite}#symbol-${icon}`} />
    </svg>
  );
};

Icon.propTypes = {
  /** The color of the icon */
  color: PropTypes.oneOf(Colors),
  /** The name of the icon to embed (from the generated sprite) */
  icon: PropTypes.string,
  /** The width of the icon */
  width: PropTypes.number,
  /** The height of the icon */
  height: PropTypes.number,
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
};

Icon.defaultProps = {
  color: 'dark',
  icon: '',
  width: 20,
  height: 20,
  className: '',
};

// Needed for Storybook
Icon.displayName = 'Icon';

export default Icon;
