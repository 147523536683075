import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import Button from '.';

export default {
  title: 'BRMS/button/Button',
  component: Button,
};

const Template = (args) => <Button {...args} />;

export const Default = Template.bind({});
Default.args = {
  text: 'Default value',
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return color === 'light' ? (
        <div key={index}>
          <Button
            text={color.charAt(0).toUpperCase() + color.slice(1)}
            color={color}
          />
          <Button
            text={`${color.charAt(0).toUpperCase() + color.slice(1)} variant`}
            color={'light'}
            textColor={'assertive'}
          />
          <Button
            text={`${color.charAt(0).toUpperCase() + color.slice(1)} variant`}
            color={'light'}
            textColor={'energized'}
          />
          <Button
            text={`${color.charAt(0).toUpperCase() + color.slice(1)} variant`}
            color={'light'}
            textColor={'balanced-500'}
          />
        </div>
      ) : (
        <Button
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
        />
      );
    })}
  </>
);
export const Sizes = () => (
  <>
    <Button text={'Small'} size={'s'} />
    <Button text={'Medium'} size={'m'} />
    <Button text={'Large'} size={'l'} customData={{ download: '' }} />
  </>
);

export const Rounded = () => (
  <>
    <Button text={'Rounded Small'} rounded size={'s'} />
    <Button text={'Rounded Medium'} rounded size={'m'} />
    <Button text={'Rounded Medium'} rounded color={'assertive'} size={'m'} />
    <Button text={'Rounded Medium'} rounded color={'energized'} size={'m'} />
    <Button text={'Rounded Large'} rounded size={'l'} />
  </>
);

export const Outline = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <Button
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          outline
        />
      );
    })}
  </>
);

export const Link = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <Button
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          link
        />
      );
    })}
  </>
);

export const Variants = () => (
  <>
    <Button
      text={'Assertive combined'}
      color={'stable-500'}
      textColor={'assertive'}
      outline
    />
    <Button
      text={'Multiple actions'}
      color={'positive'}
      icon={{ icon: 'plus' }}
      action={[
        {
          text: 'Action 1',
          action: '#',
          color: 'light',
        },
        {
          text: 'Action 2',
          action: '#',
          color: 'light',
        },
      ]}
    />
  </>
);
