import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactSelect from 'react-select';
import Rails from '@rails/ujs';
import IconRight from '@components/button/icon-right';
import Badge from '@components/badge/badge';

import Blanket from './partials/blanket';
import Menu from './partials/menu';
import MenuItem from './partials/menuItem';

import styles from './ajaxselect.module.scss';

const cx = classNames.bind(styles);

const selectStyles = {
  control: (provided) => ({
    ...provided,
    margin: 20,
    fontFamily: 'var(--font-stack)',
    fontSize: 14,
  }),
  menu: () => ({ marginTop: 10 }),
  menuList: (provided) => ({ ...provided, maxHeight: 340 }),
};
// The complete dropdown, where the select functionality is nested
const Dropdown = ({ children, isOpen, target, onClose, className }) => (
  <div className={className}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

Dropdown.propTypes = {
  // Fill the dropdown with items
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // Add custom class to the dropdown
  className: PropTypes.string.isRequired,
  // Trigger function to close the dropdown when clicked outside
  onClose: PropTypes.func.isRequired,
  // Boolean to track the state of the menu
  isOpen: PropTypes.bool.isRequired,
  // Interface to activate the dropdown
  target: PropTypes.shape({}),
};

Dropdown.defaultProps = {
  target: {},
};

// Custom React Select functionality
const AjaxSelect = ({
  description,
  method,
  name,
  placeholder,
  url,
  defaultItem,
  errors,
  data,
  onSelect,
  open,
}) => {
  const [isOpen, setOpen] = useState(open);
  const [value, setValue] = useState({});
  const [items, setItems] = useState();
  const [search, setSearch] = useState('');

  const timeoutId = useRef();

  const searchDB = () => {
    const callback = {
      url: url + search,
      method,
    };
    Rails.ajax({
      type: callback.method,
      url: callback.url,
      success(response) {
        setItems(response);
      },
    });
  };

  useEffect(() => {
    if (Object.keys(defaultItem).length > 0) setSearch(defaultItem.value);
  }, [defaultItem]);

  useEffect(() => {
    clearTimeout(timeoutId.current);
    if (!search.trim()) {
      setItems([]);
      return;
    }
    timeoutId.current = setTimeout(() => {
      searchDB();
    }, 300);
  }, [search]);

  const classes = cx({
    select: true,
  });

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const onSelectChange = (v) => {
    toggleOpen();
    setValue(v);
    setSearch(v.value);
    onSelect && onSelect(v);
  };

  const onChange = (e, { action }) => {
    if (action === 'input-change') {
      setSearch(e);
    }
  };

  const MenuToggle = ({ action, selectedValue }) => {
    const toggleClasses = cx({
      menutoggle: true,
      isopen: isOpen,
      errorstate: errors && errors.length > 0,
    });

    return (
      <>
        <div className={styles.badgewrapper}>
          {errors &&
            errors.map((error, index) => (
              <Badge
                key={index}
                color={'assertive'}
                size={'s'}
                className={styles.badge}
                content={
                  typeof error === 'object'
                    ? error
                    : {
                        text: error,
                      }
                }
              />
            ))}
        </div>
        <IconRight
          action={action}
          className={toggleClasses}
          text={
            selectedValue.value ||
            (defaultItem && defaultItem.value) ||
            description
          }
          icon={{ icon: 'chevron-up', width: 10, height: 10 }}
          outline
          textColor={'dark'}
          color={'stable-500'}
          rotatable={{
            transform: isOpen && 'rotate(180deg)',
          }}
        />
        <input
          type='hidden'
          name={name || ''}
          value={selectedValue.id || (defaultItem && defaultItem.id)}
        />
      </>
    );
  };

  MenuToggle.propTypes = {
    action: PropTypes.func.isRequired,
    selectedValue: PropTypes.shape({
      value: PropTypes.string,
      id: PropTypes.number,
    }),
  };

  MenuToggle.defaultProps = {
    selectedValue: {},
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      classNamePrefix={'select'}
      className={classes}
      target={<MenuToggle action={toggleOpen} selectedValue={value} />}
    >
      <ReactSelect
        autoFocus
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator: null,
          IndicatorSeparator: null,
          Option: MenuItem,
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        inputValue={search}
        isClearable={false}
        menuIsOpen
        onChange={onSelectChange}
        onInputChange={onChange}
        onSelectResetsInput={false}
        options={data || items}
        placeholder={placeholder}
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
      />
    </Dropdown>
  );
};

AjaxSelect.propTypes = {
  description: PropTypes.string,
  // Placeholder for the input field
  placeholder: PropTypes.string,
  // Url for requests
  url: PropTypes.string,
  // Method of call
  method: PropTypes.string,
  // Name of input field
  name: PropTypes.string,
  // Set a item as default
  defaultItem: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string,
  }),
  // Error state
  errors: PropTypes.arrayOf(PropTypes.string),
  // Prefill the list (for Storybook)
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /** Action to take on select item */
  onSelect: PropTypes.func,
  /** Whether the select should be opened by default */
  open: PropTypes.bool,
};

AjaxSelect.defaultProps = {
  defaultItem: {},
  description: 'Select a rule',
  errors: [],
  method: 'GET',
  name: '',
  placeholder: 'Search',
  url: '/admin/rules.json?q[name_cont]=',
  data: null,
  onSelect: null,
  open: false,
};

AjaxSelect.displayName = 'AjaxSelect';

export default AjaxSelect;
