import React from 'react';
import RunningModal from '.';

export default {
  title: 'BRMS/layout/testing/modals/running',
  component: RunningModal,
};

const Template = (args) => <RunningModal {...args} />;

export const Default = Template.bind({});

Default.args = {
  progress: 70,
  isOpen: true,
  header: {
    title: {
      text: 'Test run 1 running...',
    },
    content: {
      text: '2.000/10.000',
    },
    details: [
      {
        row: true,
        label: {
          text: 'Started at',
          color: 'balanced-500',
        },
        value: {
          text: '25-08-2021 14:34',
        },
      },
      {
        row: true,
        label: {
          text: 'Estimated finished at',
          color: 'balanced-500',
        },
        value: {
          text: '25-08-2021 15:09 (20 min, 23 sec)',
        },
      },
    ],
  },
  footer: {
    buttonsRight: [
      {
        color: 'stable-500',
        text: 'Cancel test',
        textColor: 'assertive',
        outline: true,
      },
      {
        color: 'stable-500',
        textColor: 'positive',
        text: 'Hide panel',
        outline: true,
      },
    ],
  },
  details: [
    {
      label: {
        text: 'Possible configurations',
      },
      value: {
        text: '1.265.262.352.623',
        color: 'dark',
      },
    },
    {
      label: {
        text: 'Chosen configurations',
      },
      value: {
        text: '10.000',
        color: 'dark',
      },
    },
    {
      label: {
        text: 'Failed configurations',
      },
      value: {
        status: {
          color: 'royal',
          icon: {
            icon: 'warning',
          },
          title: {
            text: '5',
          },
        },
      },
    },
    {
      label: {
        text: 'Errors encountered',
      },
      value: {
        status: {
          color: 'assertive',
          icon: {
            icon: 'warning',
          },
          title: {
            text: '1',
          },
        },
      },
    },
  ],
  accordion: [
    {
      title: {
        text: 'Input settings',
      },
      settings: [
        {
          content: {
            text: 'External variables',
          },
          details: [
            {
              row: true,
              label: {
                text: 'Max issurance',
                color: 'balanced-500',
              },
              value: {
                text: '30.000, 40.000, 50.000, 60.000, 70.000',
                color: 'dark',
              },
            },
          ],
        },
        {
          content: {
            text: 'Rule input',
          },
          details: [
            {
              row: true,
              label: {
                text: 'Whats your date of birth?',
                color: 'balanced-500',
              },
              value: {
                text:
                  '01-01-2000, 01-01-2002, 01-01-2004, 01-01-2006, 01-01-2008',
                color: 'dark',
              },
            },
            {
              row: true,
              label: {
                text: 'When did the accident happen?',
                color: 'balanced-500',
              },
              value: {
                text: '12-06-2018, 07-01-2003, 10-05-2019, 20-12-2020',
                color: 'dark',
              },
            },
            {
              row: true,
              label: {
                text: 'How many days did you spend in the hospital?',
                color: 'balanced-500',
              },
              value: {
                text: '1, 3, 6, 8, 10',
                color: 'dark',
              },
            },
          ],
        },
      ],
    },
    {
      title: {
        text: 'Failed configurations & Errors',
      },
      cards: [
        {
          header: {
            status: {
              icon: {
                icon: 'warning',
              },
              title: {
                text: 'Configuration 1',
                color: 'dark',
              },
              color: 'royal',
            },
            button: {
              text: 'Recreate session',
            },
          },
          body: {
            upperDetails: [
              {
                label: {
                  text: 'Uncompleted subsets',
                  color: 'balanced-500',
                },
                value: {
                  text: 'none',
                },
              },
              {
                label: {
                  text: 'Unfinished subsets',
                  color: 'balanced-500',
                },
                value: {
                  text: 'Subset 1, Subset 3, Subset 5',
                },
              },
            ],
            lowerDetails: [
              {
                content: {
                  text: 'Failed configurations',
                },
                detail: {
                  label: {
                    text: 'Hospital check',
                    color: 'balanced-500',
                  },
                  badges: [
                    {
                      values: [
                        {
                          content: {
                            text: 'illness',
                          },
                          color: 'positive',
                        },
                        {
                          content: {
                            text: '==',
                          },
                          color: 'balanced-300',
                        },
                        {
                          content: {
                            text: 'false',
                          },
                          color: 'assertive',
                        },
                      ],
                    },
                  ],
                },
              },
              {
                content: {
                  text: 'Errors',
                },
                detail: {
                  label: {
                    text: 'No errors',
                    color: 'balanced-500',
                  },
                },
              },
              {
                content: {
                  text: 'External variable inputs',
                },
                detail: {
                  label: {
                    text: 'max_insurance',
                    color: 'balanced-500',
                  },
                  value: {
                    text: '30.000',
                    color: 'dark',
                  },
                },
              },
              {
                content: {
                  text: 'Rule inputs',
                },
                detail: {
                  label: {
                    text: 'Whats your date of birth?',
                    color: 'balanced-500',
                  },
                  value: {
                    text: '01-01-2000',
                    color: 'dark',
                  },
                },
              },
            ],
          },
        },
        {
          header: {
            status: {
              icon: {
                icon: 'warning',
              },
              title: {
                text: 'Configuration 1',
                color: 'dark',
              },
              color: 'royal',
            },
            button: {
              text: 'Recreate session',
            },
          },
          body: {
            upperDetails: [
              {
                label: {
                  text: 'Uncompleted subsets',
                  color: 'balanced-500',
                },
                value: {
                  text: 'none',
                },
              },
              {
                label: {
                  text: 'Unfinished subsets',
                  color: 'balanced-500',
                },
                value: {
                  text: 'Subset 1, Subset 3, Subset 5',
                },
              },
            ],
            lowerDetails: [
              {
                content: {
                  text: 'Failed configurations',
                },
                detail: {
                  label: {
                    text: 'Hospital check',
                    color: 'balanced-500',
                  },
                  badges: [
                    {
                      values: [
                        {
                          content: {
                            text: 'illness',
                          },
                          color: 'positive',
                        },
                        {
                          content: {
                            text: '==',
                          },
                          color: 'balanced-300',
                        },
                        {
                          content: {
                            text: 'false',
                          },
                          color: 'assertive',
                        },
                      ],
                    },
                  ],
                },
              },
              {
                content: {
                  text: 'Errors',
                },
                detail: {
                  label: {
                    text: 'No errors',
                    color: 'balanced-500',
                  },
                },
              },
              {
                content: {
                  text: 'External variable inputs',
                },
                detail: {
                  label: {
                    text: 'max_insurance',
                    color: 'balanced-500',
                  },
                  value: {
                    text: '30.000',
                    color: 'dark',
                  },
                },
              },
              {
                content: {
                  text: 'Rule inputs',
                },
                detail: {
                  label: {
                    text: 'Whats your date of birth?',
                    color: 'balanced-500',
                  },
                  value: {
                    text: '01-01-2000',
                    color: 'dark',
                  },
                },
              },
            ],
          },
        },
      ],
    },
  ],
};
