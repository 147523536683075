import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './group.module.scss';
import Option from '../option';

const cx = classNames.bind(styles);

const Group = (props) => {
  const [localValue, setValue] = useState(
    props.options.filter((prop) => prop.checked).map((p) => p.value)
  );
  const classes = cx({
    wrapper: true,
    column: props.column,
    button: props.button,
  });

  const changeValue = (elem) => {
    let value = [];
    if (elem.target.checked) {
      value = [...localValue, elem.target.value];
      setValue(value);
    } else {
      value = localValue.filter((name) => name != elem.target.value);
      setValue(value);
    }
    props.onChange && props.onChange(value, 'checkbox');
  };
  return (
    <div className={classes}>
      {props.options &&
        props.options.map((option, index) => (
          <Option
            key={index}
            id={index > 0 ? `${props.id}_${index}` : props.id}
            name={props.name}
            type={props.type}
            disabled={props.disabled}
            className={styles.option}
            button={props.button}
            onChange={(e) => {
              if (props.onChange) {
                if (props.type == 'checkbox') {
                  changeValue(e);
                } else {
                  props.onChange(e);
                }
              }
            }}
            {...option}
            attributes={props.attributes}
          />
        ))}
    </div>
  );
};

Group.propTypes = {
  /** The options  */
  options: PropTypes.arrayOf(PropTypes.shape(Option.propTypes)),
  column: PropTypes.bool,
  button: PropTypes.bool,
};

Group.defaultProps = {};

// Needed for Storybook
Group.displayName = 'Group';

export default Group;
