import React from 'react';
import Diagram from '.';
import dummyData from './data.json';

export default {
  title: 'BRMS/layout/Diagram',
  component: Diagram,
  parameters: {
    componentSubtitle:
      'This component makes use of https://github.com/MrBlenny/react-flow-chart',
  },
};

const Template = (args) => (
  <div
    style={{
      overflow: 'auto',
    }}
  >
    <Diagram
      {...args}
      data={dummyData}
      autolayout
      storybook
      completions={[
        {
          name: 'hospital',
          value: 'hospital',
          score: 1,
          meta: 'rule in current subset',
        },
        {
          name: 'illness',
          value: 'illness',
          score: 1,
          meta: 'rule in current subset',
        },
      ]}
      positions={{ node1: { x: 200, y: 200 }, node3: { x: 300, y: 450 } }}
    />
  </div>
);

export const Default = Template.bind({});
