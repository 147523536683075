import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Content from '@components/text/content';
import Button from '@components/button/button';
import Wrapper from '../wrapper';
import styles from './collapsible.module.scss';

const cx = classNames.bind(styles);

const CollapsibleCard = ({ className, items, open }) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };
  const classes = cx(
    {
      card: true,
    },
    className
  );

  return (
    <Wrapper size={'no-p'} className={classes}>
      <Button
        icon={{
          width: 7,
          height: 7,
          icon: isOpen ? 'toggle-close' : 'toggle-open',
          color: 'dark',
        }}
        color={'stable-500'}
        action={toggleCard}
        className={styles.toggle}
        size={'xs'}
      />
      <div className={styles.items}>
        {items.length > 0 &&
          items.map((item, index) =>
            index > 0 && !isOpen ? null : item.action ? (
              <Button
                key={index}
                color={index == 0 ? 'light' : 'stable-300'}
                textColor={'positive'}
                className={styles.btn}
                {...item}
              />
            ) : (
              <Content
                key={index}
                color={'dark'}
                className={styles.text}
                size={'m'}
                emphasis
                {...item}
              />
            )
          )}
      </div>
    </Wrapper>
  );
};

CollapsibleCard.propTypes = {
  /** The items in the card, the first one will always be visible */
  items: PropTypes.arrayOf(
    PropTypes.shape(Content.PropTypes),
    PropTypes.shape(Button.propTypes)
  ),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Initial state of the card, default closed */
  open: PropTypes.string,
};

CollapsibleCard.defaultProps = {
  items: [],
  className: '',
  open: false,
};

// Needed for Storybook
CollapsibleCard.displayName = 'CollapsibleCard';

export default CollapsibleCard;
