import React from 'react';
import ConfigurationCard from '.';

export default {
  title: 'BRMS/card/ConfigurationCard',
  component: ConfigurationCard,
};

const Template = (args) => (
  <>
    <ConfigurationCard {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  header: {
    status: {
      icon: {
        icon: 'warning',
      },
      title: {
        text: 'Configuration 1',
        color: 'dark',
      },
      color: 'royal',
    },
    button: {
      text: 'Recreate session',
    },
  },
  body: {
    upperDetails: [
      {
        label: {
          text: 'Uncompleted subsets',
          color: 'balanced-500',
        },
        value: {
          text: 'none',
        },
      },
      {
        label: {
          text: 'Unfinished subsets',
          color: 'balanced-500',
        },
        value: {
          text: 'Subset 1, Subset 3, Subset 5',
        },
      },
    ],
    lowerDetails: [
      {
        content: {
          text: 'Failed configurations',
        },
        detail: {
          label: {
            text: 'Hospital check',
            color: 'balanced-500',
          },
          badges: [
            {
              values: [
                {
                  content: {
                    text: 'illness',
                  },
                  color: 'positive',
                },
                {
                  content: {
                    text: '==',
                  },
                  color: 'balanced-300',
                },
                {
                  content: {
                    text: 'false',
                  },
                  color: 'assertive',
                },
              ],
            },
          ],
        },
      },
      {
        content: {
          text: 'Errors',
        },
        detail: {
          label: {
            text: 'No errors',
            color: 'balanced-500',
          },
        },
      },
      {
        content: {
          text: 'External variable inputs',
        },
        detail: {
          label: {
            text: 'max_insurance',
            color: 'balanced-500',
          },
          value: {
            text: '30.000',
            color: 'dark',
          },
        },
      },
      {
        content: {
          text: 'Rule inputs',
        },
        detail: {
          label: {
            text: 'Whats your date of birth?',
            color: 'balanced-500',
          },
          value: {
            text: '01-01-2000',
            color: 'dark',
          },
        },
      },
    ],
  },
};
