import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../diagram.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prev, next) => prev === next;

const CustomNode = React.forwardRef(({ children, ...otherProps }, ref) => {
  if (otherProps.node.hidden) {
    return <div />;
  }
  const muteableProps = otherProps;
  const nodeClasses = cx({
    node: true,
    'react-draggable': true,
    nodeSelected: muteableProps.isSelected,
  });
  const { config } = muteableProps;
  delete muteableProps.isSelected;
  delete muteableProps.config;
  return (
    <div
      ref={ref}
      {...muteableProps}
      config={config}
      className={nodeClasses}
      aria-hidden='true'
    >
      <div>{children}</div>
    </div>
  );
});

CustomNode.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(CustomNode, checkEqual);
