import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors } from '@components/variables';
import styles from './divider.module.scss';

const cx = classNames.bind(styles);

const Divider = (props) => {
  const classes = cx(
    {
      divider: true,
    },
    props.color,
    props.className
  );

  const generateSpacing = (style, values) => {
    if (!values) {
      return;
    }
    if (values.length === 1) {
      return { [style]: values[0] };
    }
    const _generateCss = (...values) => ({
      [`${style}Top`]: values[0],
      [`${style}Right`]: values[1],
      [`${style}Bottom`]: values[2],
      [`${style}Left`]: values[3],
    });
    if (values.length === 2) {
      return _generateCss(values[0], values[1], values[0], values[1]);
    }
    if (values.length === 3) {
      return _generateCss(values[0], values[1], values[2], values[1]);
    }
    return _generateCss(values[0], values[1], values[2], values[3]);
  };
  const CustomComponent = props.color == 'transparent' ? 'span' : 'hr';
  return (
    <CustomComponent
      className={classes}
      style={{
        height: props.height,
        width: props.width,
        ...generateSpacing('margin', props.margin),
        ...props.style,
      }}
    />
  );
};

Divider.propTypes = {
  /** The color of the title */
  color: PropTypes.oneOf(Colors).isRequired,
  /** The height of the divider */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The width of the divider */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Divider.defaultProps = {
  color: 'stable-500',
};

// Needed for Storybook
Divider.displayName = 'Divider';

export default Divider;
