import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import Badge from '@components/badge/badge';
import Title from '@components/text/title';
import Detail from '@components/text/detail';
import Tooltip from '@components/ui/tooltip';

import styles from '../ajaxselect.module.scss';

const cx = classNames.bind(styles);

// A single item inside the dropdown
const MenuItem = ({ value, innerProps, data }) => {
  const classes = cx({
    menuitem: true,
  });
  const { detail, badges, tooltip } = data;
  return (
    <div className={classes} {...innerProps}>
      <Title text={value} className={styles.title} />
      <Detail value={{ text: detail }} className={styles.detail} />
      <div className={styles.badges}>
        {badges &&
          badges.map((badge, i) =>
            i > 1 && i < 3 ? (
              <Badge
                key={i}
                content={{ text: `+${i} more` }}
                color={'balanced-100'}
                className={styles.badge}
              />
            ) : (
              i < 2 && <Badge {...badge} className={styles.badge} key={i} />
            )
          )}
      </div>
      {tooltip && (
        <Tooltip
          position={'bottom'}
          trigger={'mouseenter'}
          button={{
            icon: { icon: 'eye-round' },
            link: true,
            text: 'Preview',
          }}
          className={styles.tooltip}
          content={tooltip}
          {...tooltip}
        />
      )}
    </div>
  );
};

MenuItem.propTypes = {
  // Value is required to be searched on
  value: PropTypes.string.isRequired,
  // InnerProps is required through the React Select library
  innerProps: PropTypes.shape({}).isRequired,
  // Data contains custom props
  data: PropTypes.shape({
    // Description for date
    detail: PropTypes.string,
    // Badges for within the card
    badges: PropTypes.arrayOf(PropTypes.shape(Badge.propTypes)),
    // Tooltips for within the card
    tooltip: PropTypes.shape(Tooltip.propTypes),
  }),
};

MenuItem.defaultProps = {
  data: {},
};

export default MenuItem;
