import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import IconLeft from '.';

export default {
  title: 'BRMS/button/IconLeft',
  component: IconLeft,
};

const Template = (args) => <IconLeft {...args} />;

export const Default = Template.bind({});
Default.args = { text: 'IconLeft', icon: { icon: 'empty' } };

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconLeft
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          {...Default.args}
        />
      );
    })}
  </>
);

export const Outline = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconLeft
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          outline
          {...Default.args}
        />
      );
    })}
  </>
);

export const Link = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconLeft
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          link
          {...Default.args}
        />
      );
    })}
  </>
);

export const Size = () => (
  <>
    <IconLeft text={'Small'} size={'s'} {...Default.args} />
    <IconLeft text={'Medium'} size={'m'} {...Default.args} />
    <IconLeft text={'Large'} size={'l'} {...Default.args} />
  </>
);

export const Rounded = () => (
  <>
    <IconLeft text={'Rounded Small'} rounded size={'s'} {...Default.args} />
    <IconLeft text={'Rounded Medium'} rounded size={'m'} {...Default.args} />
    <IconLeft
      text={'Rounded Medium'}
      rounded
      color={'assertive'}
      size={'m'}
      {...Default.args}
    />
    <IconLeft
      text={'Rounded Medium'}
      rounded
      color={'energized'}
      size={'m'}
      {...Default.args}
    />
    <IconLeft text={'Rounded Large'} rounded size={'l'} {...Default.args} />
  </>
);
