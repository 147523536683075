import React from 'react';
import { Divider } from '@components/ui';
import Wrapper from '.';

export default {
  title: 'BRMS/card/Wrapper',
  component: Wrapper,
  parameters: {
    componentSubtitle:
      "This component is meant to be used internally. Don't use as a React_Rails component.",
  },
};

const Template = (args) => <Wrapper {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Variants = (args) => (
  <>
    <Wrapper
      header={{
        title: {
          text: 'With header',
        },
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        title: {
          text: 'With header and subtitle',
        },
        content: {
          text: 'subtitle',
        },
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        title: {
          text: 'With no background',
        },
        content: {
          text: 'subtitle',
        },
        headerBackground: false,
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        title: {
          text: 'With colored header',
          color: 'assertive',
        },
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        title: {
          text: 'With header & card action',
        },
      }}
      action={'#'}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        title: {
          text: 'With header & icon',
        },
        detailStatus: {
          color: 'positive',
          icon: {
            icon: 'checkmark',
          },
          title: {
            text: 'Published',
          },
        },
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        title: {
          text: 'With header & button',
        },
        button: {
          text: 'Edit master rule',
        },
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        title: {
          text: 'With header & buttons',
        },
        buttons: [
          {
            icon: {
              icon: 'trash',
            },
            color: 'assertive',
          },
          {
            icon: {
              icon: 'eye-round',
            },
            color: 'positive',
          },
        ],
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        title: {
          text: 'With header & badge',
        },
        content: {
          text: 'Edit master rule',
        },
        badge: {
          label: {
            badge: {
              content: {
                text: 'True',
              },
            },
          },
          value: {
            text: 'Global',
          },
        },
        button: {
          text: 'Edit master rule',
        },
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Wrapper
      header={{
        content: {
          text: 'Edit master rule',
        },
        badge: {
          label: {
            badge: {
              content: {
                text: 'True',
              },
            },
          },
        },
        button: {
          text: 'Edit master rule',
        },
      }}
    />
  </>
);
