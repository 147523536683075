import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Colors, Spacings } from '@components/variables';
import Icon from '@components/ui/icon';
import Title from '@components/text/title';
import { _textColor } from '@components/styles';
import styles from './button.module.scss';

const cx = classNames.bind(styles);

const Button = ({
  action,
  className,
  color,
  customData,
  data,
  disabled,
  emphasis,
  grow,
  icon,
  iconRight,
  inline,
  link,
  loading,
  outline,
  rotatable,
  rounded,
  size,
  style,
  text,
  textSize,
  textColor,
  type,
}) => {
  const classes = cx(
    {
      button: true,
      outline,
      disabled,
      loading,
      reverse: iconRight,
      rounded,
      link,
      grow,
    },
    color,
    size,
    className
  );

  const iconClasses = cx({
    title: true,
    icon: true,
    withoutTitle: !text,
  });

  const CustomComponent = !action
    ? 'div'
    : typeof action === 'string'
    ? 'a'
    : 'button';

  const contentColor =
    textColor || (outline || link ? color : _textColor(color));

  const _dataToAttr = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [`data-${key}`, value])
    );

  const dataAttributes = data ? _dataToAttr(data) : [];

  const _customDataToAttr = (obj) =>
    Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value]));
  const attributes = customData ? _customDataToAttr(customData) : [];
  return typeof action === 'object' && action.length > 0 ? null : (
    <CustomComponent
      className={classes}
      href={action && typeof action === 'string' ? action : null}
      onClick={action && typeof action === 'function' ? action : null}
      type={type}
      style={style}
      {...dataAttributes}
      {...attributes}
    >
      {icon && (
        <Icon
          style={rotatable}
          color={contentColor}
          className={iconClasses}
          {...icon}
        />
      )}
      {text && (
        <Title
          text={text}
          inline={inline}
          className={styles.title}
          size={size === 's' || size === 'xs' ? 'label' : textSize || 'body'}
          color={contentColor}
          emphasis={emphasis === !undefined ? false : emphasis}
        />
      )}
    </CustomComponent>
  );
};

Button.propTypes = {
  /** The action onclick can be an url, a (js) function or an array of buttons. In case of an array, the tooltip component with actions will be rendered */
  action: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.func,
  ]),
  /** Adds a custom class to the button */
  className: PropTypes.string,
  /** The color variant of the button */
  color: PropTypes.oneOf(Colors),
  /** Array of key: value pairs of custom attributes (like target="_blank", download) */
  customData: PropTypes.objectOf(PropTypes.string),
  /** Array of key: value pairs of data-attributes (the key should be added without the data- part) */
  data: PropTypes.objectOf(PropTypes.string),
  /** Disables the button  */
  disabled: PropTypes.bool,
  /** Whether the font-weight should be 500 instead oof 700  */
  emphasis: PropTypes.bool,
  /** Adds an icon to the button  */
  icon: PropTypes.shape(Icon.propTypes),
  /** Places the added icon to the right of the button  */
  iconRight: PropTypes.bool,
  /** Regular link  */
  link: PropTypes.bool,
  /** Displays the loading class on the button  */
  loading: PropTypes.bool,
  /** Outlined button  */
  outline: PropTypes.bool,
  /** Rounded button  */
  rounded: PropTypes.bool,
  /** Rotatable icon  */
  rotatable: PropTypes.shape({}),
  /** Size of the button */
  size: PropTypes.oneOf(Spacings),
  /** Adds a custom style to the button */
  style: PropTypes.objectOf({}),
  /** The label of the button */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Color of the text and icon  */
  textColor: PropTypes.string,
  /** Size of the text  */
  textSize: PropTypes.string,
  /** Type of the button  */
  type: PropTypes.string,
  /** Make the button grow */
  grow: PropTypes.bool,
  /** Make the title inline */
  inline: PropTypes.bool,
};

Button.defaultProps = {
  action: () => {},
  className: '',
  color: 'positive',
  customData: null,
  data: null,
  disabled: false,
  emphasis: undefined,
  grow: false,
  icon: null,
  iconRight: false,
  link: false,
  loading: false,
  outline: false,
  rotatable: {},
  rounded: false,
  size: 'm',
  style: null,
  text: '',
  textColor: '',
  inline: false,
  type: '',
};

// Needed for Storybook
Button.displayName = 'Button';

export default Button;
