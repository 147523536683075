import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import { Content } from '@components/text';
import Icon from '.';

const iconNames = require('./icons.json');

export default {
  title: 'BRMS/ui/Icon',
  component: Icon,
};

const Template = (args) => <Icon {...args} />;

export const Default = Template.bind({});
Default.args = {
  icon: 'empty',
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return <Icon key={index} icon={'empty'} color={color} />;
    })}
  </>
);

export const Available = () => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {iconNames.children.map((icon, index) => {
      const name = icon.name.replace(icon.extension, '');
      const w = 22;
      const h = 22;
      return (
        <div key={index}>
          <div
            style={{
              width: 100,
              height: 100,
              margin: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid rgba(var(--color-stable-500), 1)',
            }}
          >
            <Icon icon={name} width={w} height={h} />
          </div>
          <Content text={name} align={'center'} size={'s'} />
        </div>
      );
    })}
  </div>
);

export const iconTitle = () => (
  <div>
    <Icon icon={'checkmark'} title={{ text: 'checkmark' }} color={'positive'} />
  </div>
);

export const iconTitleAndBackground = () => (
  <div>
    <Icon
      icon={'checkmark'}
      title={{ text: 'checkmark' }}
      color={'positive'}
      background
    />
  </div>
);
