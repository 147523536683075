import React from 'react';
import { Divider } from '@components/ui';
import { Title } from '@components/text';
import Select from '.';

const customOptions = require('./customOptions.json');

export default {
  title: 'BRMS/form/Select',
  component: Select,
  parameters: {
    componentSubtitle:
      'Uses React-Select (https://github.com/JedWatson/react-select) under the hood',
  },
};

const Template = (args) => (
  <>
    <Select {...args} />
    <Divider height={100} color={'transparent'} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  id: 'select',
  options: [
    { value: 'chocolate', label: 'Chocolate', checked: true },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ],
};

export const Variants = (args) => (
  <>
    <Title text={'Disabled'} size={'label'} />
    <Select {...Default.args} disabled />
    <Divider height={20} color={'transparent'} />
    <Title text={'Multiple'} size={'label'} />
    <Select {...Default.args} multi />
    <Divider height={100} color={'transparent'} />
  </>
);

export const limited = () => (
  <>
    <Title text={'Limited to 5 options'} size={'label'} />
    <Select id={'selecty'} options={customOptions} limit={5} />
  </>
);
