import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Colors, Spacings } from '@components/variables';
import Wrapper from '../wrapper';

const HtmlCard = ({ size, color, id, header, className }) => {
  const divWrapper = useRef();
  useEffect(() => {
    if (divWrapper.current) {
      const template = id ? document.getElementById(id) : divWrapper.current.closest('[data-html-card-parent]').querySelector('[data-template]');
      if (template && divWrapper.current) {
        if (template.tagName.toLocaleLowerCase() === 'template') {
          const clone = template.content.cloneNode(true);
          divWrapper.current.appendChild(clone);
        } else {
          divWrapper.current.appendChild(template);
          template.classList.remove('u-hide');
        }
        if (typeof ReactRailsUJS !== 'undefined') {
          ReactRailsUJS.mountComponents(divWrapper.current);
        }
      }
    }
  }, []);
  return (
    <Wrapper size={size} color={color} header={header} className={className}>
      <div ref={divWrapper} />
    </Wrapper>
  );
};

HtmlCard.propTypes = {
  /** The id of the hidden template to be placed inside this card */
  id: PropTypes.string,
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The header of the card */
  header: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  /** Adds a custom class to the card */
  className: PropTypes.string,
};

HtmlCard.defaultProps = {
  id: '',
  color: 'light',
  size: 'l',
  header: false,
  className: '',
};

// Needed for Storybook
HtmlCard.displayName = 'HtmlCard';

export default HtmlCard;
