import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Alignments } from '@components/variables';
import styles from './title.module.scss';

const cx = classNames.bind(styles);

const Title = ({ align, className, color, emphasis, inline, size, text }) => {
  const classes = cx(
    {
      title: true,
      emphasis,
    },
    color,
    align,
    size,
    className
  );
  const CustomTag = inline
    ? 'span'
    : ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(size.toLowerCase())
    ? size.toLowerCase()
    : 'p';
  return <CustomTag className={classes}>{text}</CustomTag>;
};

Title.propTypes = {
  /** The text of the title */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The color of the title */
  color: PropTypes.oneOf(Colors),
  /** The size of the title, will be rendered as element (p in case of body) */
  size: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body',
    'label',
    'small',
  ]),
  /** The alignment of the title */
  align: PropTypes.oneOf(Alignments),
  /** Whether the title should be rendered inline (inside a span) */
  inline: PropTypes.bool,
  /** Whether the font-weight should be 500 instead of 700 */
  emphasis: PropTypes.bool,
  className: PropTypes.string,
};

Title.defaultProps = {
  align: 'left',
  className: '',
  color: 'dark',
  emphasis: false,
  inline: false,
  size: 'h2',
  text: '',
};

// Needed for Storybook
Title.displayName = 'Title';

export default Title;
