import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './simple.module.scss';

const cx = classNames.bind(styles);

const Simple = ({
  value,
  onChange,
  readonly,
  className,
  attributes,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const classes = cx(
    {
      input: true,
    },
    className
  );
  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);
  return (
    <input
      className={classes}
      readOnly={readonly}
      value={internalValue !== null ? internalValue : ''}
      onChange={(e) => {
        setInternalValue(e.target.value);
        if (onChange) {
          onChange(e);
        }
      }}
      {...props}
      {...attributes}
    />
  );
};

Simple.propTypes = {
  /** The input type */
  type: PropTypes.oneOf([
    'text',
    'password',
    'tel',
    'email',
    'url',
    'hidden',
    'number',
  ]).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
  /** Function to trigger on onchange */
  onChange: PropTypes.func,
};

Simple.defaultProps = {
  value: null,
  placeholder: '',
  disabled: false,
  readonly: false,
  onChange: null,
};

// Needed for Storybook
Simple.displayName = 'Simple';

export default Simple;
