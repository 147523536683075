import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import Group from '.';

export default {
  title: 'BRMS/button/Group',
  component: Group,
};

const Template = (args) => {
  return <Group {...args} />;
};

export const Default = Template.bind({});

Default.args = {
  buttons: [
    {
      text: 'button',
      size: 'm',
    },
    {
      text: 'button',
      size: 'm',
    },
    {
      text: 'button',
      size: 'm',
    },
  ],
};

const sizes = ['s', 'm', 'l'];

export const Sizes = () => (
  <>
    <Group
      buttons={sizes.map((size) => {
        return { size, text: `${size} size` };
      })}
    />
  </>
);

export const Colors = () => (
  <>
    <Group
      buttons={ColorNames.map((color) => {
        return { color, text: color.charAt(0).toUpperCase() + color.slice(1) };
      })}
    />
  </>
);

export const IconLeft = () => (
  <>
    <Group
      buttons={ColorNames.map((color) => {
        return {
          color,
          text: color.charAt(0).toUpperCase() + color.slice(1),
          icon: { icon: 'empty' },
        };
      })}
    />
  </>
);

export const iconRight = () => (
  <>
    <Group
      buttons={ColorNames.map((color) => {
        return {
          color,
          text: color.charAt(0).toUpperCase() + color.slice(1),
          icon: { icon: 'empty' },
          iconRight: true,
        };
      })}
    />
  </>
);
