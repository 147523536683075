import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import Button from '@components/button/button';
import { Content, Detail, Status } from '@components/text';
import styles from './configurationCard.module.scss';

const cx = classNames.bind(styles);

const ConfigurationCard = ({ className, header, body, active }) => {
  const [collapsed, setCollapsed] = useState(active);
  const classes = cx(
    {
      configurationCard: true,
    },
    className
  );

  const handleClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={classes}>
      {header && (
        <div className={styles.header}>
          {header.status && <Status {...header.status} />}
          <div className={styles.headerButtons}>
            {header.button && <Button link {...header.button} />}
            <Button
              color={'dark'}
              icon={{ icon: 'chevron-up', width: 10, height: 5 }}
              link
              style={{ transform: collapsed && 'rotate(180deg)' }}
              action={handleClick}
              {...header.arrow}
            />
          </div>
        </div>
      )}
      {header && body && <Divider height={1} color={'stable-500'} />}
      {body && collapsed && (
        <div className={styles.body}>
          {body.upperDetails?.map((item, index) => (
            <Detail key={index} row {...item} />
          ))}
          {body.upperDetails && body.lowerDetails && (
            <Divider height={15} color={'transparent'} />
          )}
          {body.lowerDetails?.map((item, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <Divider height={15} color={'transparent'} />}
              {item.content && (
                <Content color={'dark'} size={'s'} {...item.content} />
              )}
              {item.detail && <Detail row {...item.detail} />}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

ConfigurationCard.propTypes = {
  /** Adds a custom class to the card */
  className: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.shape(Status.propTypes),
    PropTypes.shape(Button.propTypes),
  ]),
  body: PropTypes.oneOfType([PropTypes.shape(Detail.propTypes)]),
  active: PropTypes.bool,
};

ConfigurationCard.defaultProps = {
  className: '',
  header: null,
  body: null,
  active: false,
};

// Needed for Storybook
ConfigurationCard.displayName = 'ConfigurationCard';

export default ConfigurationCard;
