import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../diagram.module.scss';

const cx = classNames.bind(styles);

// Compare on port level
const checkEqual = (prev, next) => prev === next;

const CustomPort = ({ port }) => {
  const dotClasses = cx({
    dot: true,
    dotIn: port.properties.type === 'in',
    dotOut: port.properties.type === 'out',
    termination: port.properties.termination,
  });
  return (
    <div className={styles.dotWrapper}>
      <div>
        <div className={dotClasses} />
      </div>
    </div>
  );
};

CustomPort.propTypes = {
  port: PropTypes.shape({
    properties: PropTypes.shape({
      type: PropTypes.string,
      child: PropTypes.bool,
      termination: PropTypes.bool,
    }),
  }).isRequired,
};

export default React.memo(CustomPort, checkEqual);
