import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Modal from 'react-modal';
import Wrapper from '@components/card/wrapper';
import Title from '@components/text/title';
import Content from '@components/text/content';
import Detail from '@components/text/detail';
import Button from '@components/button/button';
import { Spacings } from '@components/variables';
import styles from './modalWrapper.module.scss';

const cx = classNames.bind(styles);

const ModalWrapper = ({
  className,
  header,
  footer,
  isOpen,
  children,
  size,
  progress,
}) => {
  const wrapperRef = useRef(null);
  const [modalIsOpen, setIsOpen] = useState(isOpen);
  const [percentage, setPercentage] = useState(progress);

  const classes = cx(
    {
      modalWrapper: true,
    },
    className
  );

  const classesBody = cx(
    {
      body: true,
    },
    className
  );

  // Set start value percentage
  useEffect(() => {
    setPercentage(0);
    const timeout = setTimeout(() => {
      setPercentage(progress);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  // Outside click close modal using ref
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  return (
    modalIsOpen && (
      <div className={classes}>
        <Modal
          isOpen={modalIsOpen}
          shouldCloseOnOverlayClick
          className={styles.modalWrapper}
          ariaHideApp={false}
          contentLabel='RunningModal'
          portalClassName={'portal-node'}
        >
          <Wrapper size={size}>
            <div ref={wrapperRef}>
              {header && (
                <div className={styles.header}>
                  {(header.title || header.content || progress) && (
                    <div className={styles.headerLeft}>
                      {header.title && <Title size={'h2'} {...header.title} />}
                      {(header.content || progress) && (
                        <div className={styles.headerContent}>
                          {header.content && (
                            <Content size={'s'} {...header.content} />
                          )}
                          {progress !== 0 ? (
                            <Content
                              className={styles.percentage}
                              size={'s'}
                              color={'balanced-500'}
                              text={` (${percentage.toFixed(0)}% completed)`}
                              {...header.percentage}
                            />
                          ) : (
                            <Content
                              className={styles.percentage}
                              text={' (0% completed)'}
                              size={'s'}
                              color={'balanced-500'}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {header.details && (
                    <div className={styles.headerRight}>
                      {header.details.map((item, index) => (
                        <Detail key={index} {...item} />
                      ))}
                    </div>
                  )}
                </div>
              )}
              {progress && (
                <div className={styles.progress}>
                  <div
                    className={styles.bar}
                    style={{ width: `${percentage}%` }}
                  >
                    <div className={styles.stripes} />
                  </div>
                </div>
              )}
              {children && <div className={classesBody}>{children}</div>}
              {footer && (
                <div className={styles.footer}>
                  {footer.buttonsLeft && (
                    <div className={styles.buttonsLeft}>
                      {footer.buttonsLeft.map((item, index) => (
                        <Button
                          key={index}
                          className={styles.button}
                          {...item}
                        />
                      ))}
                    </div>
                  )}
                  {footer.buttonsRight && (
                    <div className={styles.buttonsRight}>
                      {footer.buttonsRight.map((item, index) => (
                        <Button
                          key={index}
                          className={styles.button}
                          {...item}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Wrapper>
        </Modal>
      </div>
    )
  );
};

ModalWrapper.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Header of the modal */
  header: PropTypes.shape({
    title: PropTypes.shape(Title.propTypes),
    content: PropTypes.shape(Content.propTypes),
    details: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  }),
  /** Footer of the modal */
  footer: PropTypes.shape({
    buttonsLeft: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    buttonsRight: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** To size of the card (spacing between card & content) */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** Body of the modal containing children elements */
  children: PropTypes.node,
  /** The current loading percentage */
  progress: PropTypes.number,
};

ModalWrapper.defaultProps = {
  className: '',
  header: null,
  footer: null,
  isOpen: false,
  children: null,
  progress: 0,
  size: 'no-p',
};

// Needed for Storybook
ModalWrapper.displayName = 'ModalWrapper';

export default ModalWrapper;
