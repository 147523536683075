import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { _textColor } from '@components/styles';
import { Colors } from '@components/variables';
import Content from '@components/text/content';

import styles from './badge.module.scss';

const cx = classNames.bind(styles);

const Badge = ({ className, color, size, type, content }) => {

  const contentColor =
    type === 'text'
      ? 'positive'
      : type === '!boolean'
      ? 'assertive'
      : type === 'operator'
      ? 'balanced-500'
      : type === 'boolean'
      ? content && content.text === 'false'
        ? 'assertive'
        : 'energized'
      : color;

  const classes = cx(
    {
      badge: true,
    },
    contentColor,
    className
  );

  const textColor = _textColor(contentColor);
  return (
    <div className={classes}>
      <Content size={size} color={textColor} {...content} />
    </div>
  );
};

Badge.propTypes = {
  /** Adds a custom class to the badge */
  className: PropTypes.string,
  /** The color variant of the badge */
  color: PropTypes.oneOf(Colors),
  /** Allow additional content props in content type */
  content: PropTypes.shape(Content.propTypes),
  /** The size of the badge */
  size: PropTypes.string,
  /** Type of the badge */
  type: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['operator', 'boolean', 'text', '!boolean']),
  ]),
};

Badge.defaultProps = {
  className: '',
  color: 'energized',
  content: {},
  size: 's',
  type: false,
};

// Needed for Storybook
Badge.displayName = 'Badge';

export default Badge;
