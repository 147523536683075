import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Content from '@components/text/content';
import Title from '@components/text/title';
import Button from '@components/button/button';
import Badge from '@components/badge/badge';
import Wrapper from '@components/card/wrapper';
import Icon from '@components/ui/icon';
import { Tooltip as Tippy } from 'react-tippy';
import ActionSheet from '../../action-sheet/base';
import styles from '../tooltip.module.scss';

const cx = classNames.bind(styles);

const Base = ({
  actions,
  button,
  children,
  content,
  ordered,
  position,
  selector,
  simple,
  tippyOptions,
  trigger,
  row,
  editor,
}) => {
  const classes = cx({
    simple,
  });
  return (
    <Tippy
      interactive
      html={
        row && content.length > 0 ? (
          <Wrapper
            size={'xxs'}
            color={'light'}
            className={cx({
              row,
            })}
          >
            {content.map((item, i) =>
              i === content.length - 1 ? (
                <Button text={item.text} link key={i} />
              ) : (
                <div key={i}>
                  <Button text={item.text} link />
                  <Icon
                    className={styles.icon}
                    icon={'chevron-right'}
                    color={'balanced-500'}
                    width={10}
                    height={10}
                  />
                </div>
              )
            )}
          </Wrapper>
        ) : ordered ? (
          <Wrapper size={'s'} color={'light'}>
            {content.map((item, i) =>
              i < 4 ? (
                <div key={i} className={styles.item}>
                  <Content className={styles.blob} text={i + 1} />
                  <div className={styles.content}>
                    <Title text={item.title} size={'body'} />
                    <Content size={'s'} text={item.detail || ''} />
                  </div>
                </div>
              ) : (
                i > 3 &&
                i < 5 && (
                  <Badge
                    key={i}
                    content={{ text: `+${i + 1} more` }}
                    color={'balanced-100'}
                    className={styles.badge}
                  />
                )
              )
            )}
          </Wrapper>
        ) : simple ? (
          <Wrapper size={'s'} color={'light'}>
            <Content size={'s'} {...content} />
          </Wrapper>
        ) : editor ? (
          <Wrapper size={'s'} color={'light'}>
            {content.map((item, i) => (
              <div key={i} className={styles.item}>
                <div className={styles.content}>
                  <Title text={item.key} size={'body'} color={'assertive'} />
                  <Content size={'s'} text={item.error || ''} />
                </div>
              </div>
            ))}
          </Wrapper>
        ) : actions ? (
          <ActionSheet actions={actions} />
        ) : (
          content
        )
      }
      onShown={() => {
        if (selector) {
          const tooltip = document.querySelector('.tippy-tooltip-content');
          const id = selector.startsWith('#') ? selector : `#${selector}`;
          const elem = document.querySelector(id);
          if (tooltip && elem && elem.classList.contains('u-hide')) {
            ReactDOM.unmountComponentAtNode(tooltip);
            tooltip.appendChild(elem);
            elem.classList.remove('u-hide');
            if (typeof ReactRailsUJS !== 'undefined') {
              ReactRailsUJS.mountComponents(elem);
            }
          }
        }
      }}
      onHide={() => {
        if (selector) {
          const form = document.querySelector('#table-form');
          const id = selector.startsWith('#') ? selector : `#${selector}`;
          const elem = document.querySelector(id);
          if (form && elem) {
            elem.classList.add('u-hide');
            form.appendChild(elem);
            if (typeof ReactRailsUJS !== 'undefined') {
              ReactRailsUJS && ReactRailsUJS.mountComponents(elem);
            }
          }
        }
      }}
      duration={selector ? 0 : 350}
      position={position}
      trigger={trigger}
      interactiveBorder={10}
      className={classes}
      animateFill={false}
      arrow
      theme={'light'}
      popperOptions={{
        modifiers: {
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
          flip: {
            enabled: false,
          },
        },
      }}
      {...tippyOptions}
    >
      {button ? (
        <Button
          action={(e) => {
            document.activeElement.blur();
            e.preventDefault();
          }}
          {...button}
        />
      ) : (
        children
      )}
    </Tippy>
  );
};
Base.propTypes = {
  /** The content of the tooltip, can be another Component */
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.shape(Content.propTypes),
  ]),
  /** Custom content for the tooltip */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** The position of the tooltip */
  position: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
  ]),
  /** The trigger for the tooltip */
  trigger: PropTypes.oneOf(['mouseenter', 'click']),
  /** Whether a simple tooltip should be shown */
  simple: PropTypes.bool,
  /** Whether a ordered tooltip should be shown */
  ordered: PropTypes.bool,
  /** When you want to show a (hidden) div in the tooltip, add its identifier as selector */
  selector: PropTypes.string,
  /** Additional tippy options */
  tippyOptions: PropTypes.objectOf(PropTypes.object),
  /** The button to trigger the actionsheet */
  button: PropTypes.shape(Button.propTypes),
  /** Option to make content appear in row */
  row: PropTypes.bool,
  /** The actions inside the actionsheet */
  actions: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** Whether a editor tooltip should be shown */
  editor: PropTypes.bool,
};

Base.defaultProps = {
  actions: null,
  button: null,
  children: null,
  row: false,
  content: null,
  ordered: false,
  position: 'top',
  selector: null,
  simple: false,
  tippyOptions: {},
  trigger: 'click',
  editor: false,
};

// Needed for Storybook
Base.displayName = 'Tooltip';

export default Base;
