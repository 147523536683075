import React from 'react';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';
import Detail from '.';

export default {
  title: 'BRMS/text/Detail',
  component: Detail,
};

const Template = (args) => <Detail {...args} />;

export const Default = Template.bind({});
Default.args = {
  label: {
    text: 'Default label',
  },
  value: {
    text: 'Default value',
  },
};

export const Variants = () => (
  <div style={{ flexDirection: 'row', display: 'flex' }}>
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value' }}
    />
    <Divider width={15} color={'light'} />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value' }}
      reverse
    />
  </div>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <div key={index} style={{ flexDirection: 'row', display: 'flex' }}>
          <Detail
            label={{ text: 'Default label' }}
            value={{ text: 'Default value', color }}
          />
          <Divider width={15} color={'light'} />
          <Detail
            label={{ text: 'Default label' }}
            value={{ text: 'Default value', color, emphasis: true }}
          />
          <Divider width={15} color={'light'} />
          <Detail
            label={{ text: 'Default label', color }}
            value={{ text: 'Default value' }}
            reverse
          />
        </div>
      );
    })}
  </>
);

export const Sizes = () => (
  <>
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h2)', size: 'h2' }}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h3)', size: 'h3' }}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h4)', size: 'h4' }}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h5)', size: 'h5' }}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h6)', size: 'h6' }}
    />
  </>
);

export const WithStatus = () => (
  <>
    <Detail
      label={{ text: 'Default label' }}
      value={{
        status: {
          color: 'positive',
          icon: {
            icon: 'checkmark',
          },
          title: {
            text: 'Published',
          },
        },
      }}
    />
  </>
);

export const WithValues = () => (
  <>
    <Detail
      label={{ text: 'Default label' }}
      values={[
        {
          text: 'Edited rule',
        },
        {
          text: 'what_illness.',
          action: '#',
        },
        {
          text: 'This affected subsets',
        },
        {
          text: 'basic questionaire',
          action: '#',
        },
        {
          text: 'and',
        },
        {
          text: 'illness',
          action: '#',
        },
      ]}
    />
  </>
);

export const WithBadge = () => (
  <div style={{ flexDirection: 'row', display: 'flex' }}>
    <Detail
      value={{ text: 'Default value' }}
      label={{
        badge: {
          content: {
            text:'Badge'
          }
        }
      }}
      reverse
    />
    <Divider width={15} color={'light'} />
    <Detail
      value={{ text: 'Default value' }}
      label={{
        badge: {
          content: {
            text:'Badge'
          }
        }
      }}
      reverseBadge
      reverse
    />
  </div>
);

export const WithBadges = () => (
  <>
    <Detail
      label={{ text: 'Default label' }}
      badges={[
        {
          values: [
            {
              content: {
                text: 'Badge'
              }
            },
            {
              content: {
                text: 'Badge'
              }
            }
          ]
        }
      ]}
    />
  </>
);

export const WithBadgesRow = () => (
  <>
    <Detail
      row
      label={{ text: 'Default label' }}
      badges={[
        {
          values: [
            {
              content: {
                text: 'Badge'
              }
            },
            {
              content: {
                text: 'Badge'
              }
            }
          ]
        },
        {
          values: [
            {
              content: {
                text: 'Badge'
              }
            },
            {
              content: {
                text: 'Badge'
              }
            }
          ]
        }
      ]}
    />
  </>
);

export const row = () => (
  <>
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value', color: 'dark' }}
      row
    />
  </>
);

export const rowLabelWidth= () => (
  <>
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value', color: 'dark' }}
      row
      minWidth={'xxl'}
    />
  </>
);
